import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../img/Argenco_pijl_green_rgb.svg";

import {
  Grid,
  Stack,
} from '@mui/material';

import {
  Footer,
  Hero,
  NewsItem as NewsItemTile,
  Section,
} from '../../components/v2/custom';
import {
  BlockInfo,
  IconButton,
  Page,
  TextButton,
  Typography
} from '../../components/v2/styled';

// import { Benefit as Ben } from '../../../models/Benefit';
import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const NewsDetail: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();

  const [objectId, setObjectId] = useState<string | undefined>(params.id);

  const {
    data: news,
    isLoading: newsLoading,
    // isError: adviceUploadsError,
    isSuccess: newsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "news", objectId],
    queryFn: () =>
      fetch_one<NI>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_news',
          fields: ['id', 'created_at', 'subject', 'nw_intro', 'get_item', 'block_info'],
          id: objectId
        },
        undefined
      ),
    enabled: !!objectId,
  });

  const {
    data: otherNews,
    // isLoading: adviceUploadsLoading,
    // isError: adviceUploadsError,
    isSuccess: otherNewsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "otherNews", objectId],
    queryFn: () =>
      fetch_all<NI>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_news',
          fields: ['id', 'created_at', 'subject', 'nw_intro', 'get_item'],
          per_page: 3,
          order: 'RAND() ASC',
          filter: {
            advanced: {
              enabled: 1,
              investment: 1,
              skip_id: objectId
            }
          }
        },
        undefined
      ),
    enabled: !!objectId,
  });








  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    setObjectId(params.id);
  }, [params.id]);

  return <Page>
    {!!news && <Hero
      picLeft={news.get_item}
      children={<>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{(news.subject || {})[i18n.resolvedLanguage || 'nl']}</Typography>
        {!!(news.nw_intro || {})[i18n.resolvedLanguage || 'nl'] && <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{(news.nw_intro || {})[i18n.resolvedLanguage || 'nl']}</Typography>}
      </>}
    />}

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <IconButton sx={{
            "& svg": {
              transform: "rotate(270deg)",
              width: '20px',
              marginTop: -1,
              marginLeft: -0.25
            },
            display: 'inline-block',
            marginRight: 'auto',
            height: '32px',
            width: '32px',
            paddingTop: '5px',
            backgroundColor: theme.palette.secondary.light,
          }} onClick={() => {
            navigate('/news');
          }}>
            <Arrow />
          </IconButton>
          <TextButton
            id="back_btn"
            label={t("nw.benefit.detail.back_cta")}
            onClick={() => {
              navigate('/news');
            }}
            sx={{
              fontSize: '16px',
              textDecoration: 'underline'
            }}
          />
        </Grid>
      </Grid>
    </Section>

    {!!news && <BlockInfo blockInfo={news.block_info} />}

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center', marginBottom: 4, marginTop: 8}}>{t("nw.news.detail.up_to_date_title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{
            overflowX: 'auto',
          }}>
            {otherNewsSuccess && otherNews.map(newsItem => <NewsItemTile newsItem={newsItem} sx={{
              minWidth: {xs: '99%', md: '49%', lg: '32%'},
              maxWidth: {xs: '99%', md: '49%', lg: '32%'}
            }} />)}
          </Stack>
        </Grid>
      </Grid>
    </Section>

    <Footer normalPage />
  </Page>;
}

