import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  DocumentTile,
  Footer,
  Section,
  WhiteSection
} from '../../../components/v2/custom';
import {
  LightGreenButton,
  Page,
  TransparentButton,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutTransparency: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);








  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h1" sx={{
            marginTop: 8,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.transparency.hero.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="subtitle1" html fuck_p_tags sx={{
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.transparency.hero.subsection")}</Typography>
        </Grid>
      </Grid>
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6} lg={4}>
          <DocumentTile
            date={t("nw.about.transparency.block.statuten.date")}
            name={t("nw.about.transparency.block.statuten.name")}
            lines={t("nw.about.transparency.block.statuten.lines")}
            url={t("nw.about.transparency.block.statuten.url")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DocumentTile
            date={t("nw.about.transparency.block.reglement.date")}
            name={t("nw.about.transparency.block.reglement.name")}
            lines={t("nw.about.transparency.block.reglement.lines")}
            url={t("nw.about.transparency.block.reglement.url")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DocumentTile
            date={t("nw.about.transparency.block.jaarverslag23.date")}
            name={t("nw.about.transparency.block.jaarverslag23.name")}
            lines={t("nw.about.transparency.block.jaarverslag23.lines")}
            url={t("nw.about.transparency.block.jaarverslag23.url")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DocumentTile
            date={t("nw.about.transparency.block.jaarverslag22.date")}
            name={t("nw.about.transparency.block.jaarverslag22.name")}
            lines={t("nw.about.transparency.block.jaarverslag22.lines")}
            url={t("nw.about.transparency.block.jaarverslag22.url")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DocumentTile
            date={t("nw.about.transparency.block.jaarverslag21.date")}
            name={t("nw.about.transparency.block.jaarverslag21.name")}
            lines={t("nw.about.transparency.block.jaarverslag21.lines")}
            url={t("nw.about.transparency.block.jaarverslag21.url")}
          />
        </Grid>
      </Grid>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

