import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  TransparentButton,
  Dt,
  Typography
} from '../styled';

import { NewsItem as NI } from '../../../models/NewsItem';


type Props = {
  newsItem: NI;
  sx?: any;
};

export const Investment: FunctionComponent<Props> = ({newsItem, sx}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [ctrlActive, setCtrlActive] = useState(false);
  const [stopped, setStopped] = useState(true);
  let path = `/about/investment/${newsItem.id}`;

  return (<>
    <Box
      sx={{
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1200,
        ...sx
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            if (!!path) navigate(path);
          }
          window.scrollTo(0, 0);
          setCtrlActive(false);
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
    >
      <Box sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        width: '100%',
        height: '200px',
        background: `no-repeat url(${newsItem.get_item})`,
        backgroundSize: 'cover',
      }} />

      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      <Typography variant="h3" sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        height: '72px',
        textTransform: 'none',
        marginTop: 2,
      }}>{(newsItem.subject || {})[i18n.resolvedLanguage || 'nl']}</Typography>

      <TransparentButton
        id="read_more"
        label={t("nw.cta.read_more")}
        sx={{
          marginTop: 2,
          backgroundColor: theme.palette.secondary.light,
          borderColor: 'transparent',
          "&:hover": {
            backgroundColor: theme.palette.secondary.light
          }
        }}
        // path={`/about/investment/${newsItem.id}`}
      />
    </Box>
  </>);
}