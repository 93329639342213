import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom, do_logout } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

// @ts-ignore
import LogoGreen from '../../../img/Argenco_logo_green_rgb.svg';
// @ts-ignore
import LogoWhite from '../../../img/Argenco_logo_white_rgb.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';

import {
  LightGreenButton,
  TextButton,
  Typography
} from '../../../components/v2/styled';

// import { User, loginFields } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  LanguagePicker: (b:boolean, white?:boolean) => ReactElement;
  scrolled: boolean;
}

export const MenuDesktop: FunctionComponent<Props> = ({LanguagePicker, scrolled}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [anchorEl, setAnchorEl] = React.useState<null | EventTarget & Element>(null);
  const [aboutAnchorEl, setAboutAnchorEl] = React.useState<null | EventTarget & Element>(null);
  const open = Boolean(anchorEl);
  const aboutOpen = Boolean(aboutAnchorEl);
  const [tabletOpen, setTabletOpen] = useState<boolean>(false);
  const whiteMode = window.location.pathname.indexOf("/about/mission") > -1 && !scrolled;

  // const {
  //   data: usr,
  //   // isLoading: adviceUploadsLoading,
  //   // isError: adviceUploadsError,
  //   // isSuccess: adviceUploadsSuccess,
  //   // refetch: adviceUploadsRefetch,
  // } = useQuery({
  //   queryKey: ["full_user", login?.id],
  //   queryFn: () =>
  //     fetch_one<User>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'user',
  //         fields: [...loginFields, 'last_diary_entry'],
  //         id: login?.id,
  //         ob: {
  //           id: login?.id
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!login,
  // });

  







  const SubMenuItem = (key:string, label:string, path:string) => {
    return (<MenuItem
      key={key}
      onClick={(e) => {
        setAnchorEl(null);
        setAboutAnchorEl(null);
        navigate(path);
        window.scrollTo(0, 0);
      }}
    >
      <Typography
        variant='body2'
        sx={{
          color: 'white'
        }}
      >{label}</Typography>
    </MenuItem>);
  }

  return <>
    <Box sx={{
      display: {
        xs: 'none',
        md: 'none',
        lg: 'block'
      },
      position: 'fixed',
      backgroundColor: (scrolled ? 'white' : 'transparent'),
      width: '100%',
      height: '90px',
      zIndex: 1299
    }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          textAlign: 'right',
          height: '20px',
          paddingLeft: 1,
          paddingRight: 1
        }}>
          <Container fixed>
            {!!login && <Typography variant="body2" sx={{
              display: 'inline',
              marginRight: 2
            }}>{login?.first_name} {login?.last_name} ({login?.vennoot_number})</Typography>}
            {LanguagePicker(false, !!whiteMode)}
          </Container>
        </Grid>
        <Grid item xs={12} sx={{
          height: '50px',
          paddingLeft: 1,
          paddingRight: 1,
          color: (scrolled ? theme.palette.primary.main : 'white')
        }}>
          <Container fixed>
            <Grid container spacing={0}>
              {/* DESKTOP */}
              <Grid item xs={12} sx={{marginTop: 2, textAlign: 'right'}}>
                {/* <img src={LogoWhite} style={{width: '200px', display: (scrolled ? 'none' : 'inline-block')}} />
                <img src={LogoGreen} style={{width: '200px', display: (scrolled ? 'inline-block' : 'none')}} /> */}
                <img src={whiteMode ? LogoWhite : LogoGreen} style={{width: '200px', float: 'left', cursor: 'pointer'}} onClick={() => {navigate('/'); window.scrollTo(0, 0);}} />

                <TextButton
                  id="invest"
                  label={t("nw.menu.invest")}
                  endIcon={<ExpandMoreIcon />}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/stock/") > -1}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setAboutAnchorEl(null);
                  }}
                />
                <TextButton
                  id="benefits"
                  label={t("nw.menu.benefits")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/benefit") > -1}
                  onClick={() => {
                    navigate('/benefits');
                    window.scrollTo(0, 0);
                  }}
                />
                <TextButton
                  id="about"
                  label={t("nw.menu.about")}
                  endIcon={<ExpandMoreIcon />}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/about/") > -1}
                  onClick={(e) => {
                    setAnchorEl(null);
                    setAboutAnchorEl(e.currentTarget);
                  }}
                />
                <TextButton
                  id="faq"
                  label={t("nw.menu.faq")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/faq") > -1}
                  onClick={() => {
                    navigate('/faq');
                    window.scrollTo(0, 0);
                  }}
                />
                <TextButton
                  id="news"
                  label={t("nw.menu.news")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/news") > -1}
                  onClick={() => {
                    navigate('/news');
                    window.scrollTo(0, 0);
                  }}
                />
                <TextButton
                  id="contact"
                  label={t("nw.menu.contact")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    marginRight: 4,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/contact") > -1}
                  onClick={() => {
                    navigate('/contact');
                    window.scrollTo(0, 0);
                  }}
                />
                
                {!login && <TextButton
                  id="login"
                  label={t("nw.menu.login")}
                  sx={{
                    marginTop: 0,
                    marginLeft: 'auto',
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  onClick={() => {
                    navigate('/login');
                    window.scrollTo(0, 0);
                  }}
                />}
                {!login && <LightGreenButton
                  id="register"
                  label={t("nw.menu.register")}
                  sx={{
                    marginTop: 0,
                  }}
                  onClick={() => {
                    navigate('/buy');
                    window.scrollTo(0, 0);
                  }}
                />}
                {!!login && <TextButton
                  id="log_out"
                  label={t("nw.menu.logout")}
                  sx={{
                    marginTop: 0,
                    marginLeft: 'auto',
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  onClick={() => {
                    do_logout((result) => {
                      setLogin(result);
                      window.location.reload();
                    });
                  }}
                />}
                {!!login && <LightGreenButton
                  id="my_account"
                  label={t("nw.menu.my_account")}
                  sx={{
                    marginTop: 0,
                  }}
                  onClick={() => {
                    navigate('/profile');
                    window.scrollTo(0, 0);
                  }}
                />}

                <Menu
                  id="invest-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => {
                    setAnchorEl(null);
                    setAboutAnchorEl(null);
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                    sx: {
                      backgroundColor: theme.palette.primary.main
                    }
                  }}
                >
                  {SubMenuItem("invest_why", t("nw.footer.menu.invest_why"), '/stock/why')}
                  {SubMenuItem("invest_how", t("nw.footer.menu.invest_how"), '/stock/how')}
                  {SubMenuItem("invest_transfer", t("nw.footer.menu.transfer"), '/stock/transfer')}
                  {SubMenuItem("invest_dividend", t("nw.footer.menu.dividends"), '/stock/dividend')}
                </Menu>
                <Menu
                  id="about-menu"
                  anchorEl={aboutAnchorEl}
                  open={aboutOpen}
                  onClose={() => {
                    setAnchorEl(null);
                    setAboutAnchorEl(null);
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                    sx: {
                      backgroundColor: theme.palette.primary.main
                    }
                  }}
                >
                  {SubMenuItem("about_mission_values", t("nw.footer.menu.mission_values"), '/about/mission')}
                  {SubMenuItem("about_team_rvb", t("nw.footer.menu.team_rvb"), '/about/team')}
                  {SubMenuItem("about_history", t("nw.footer.menu.history"), '/about/history')}
                  {SubMenuItem("about_investments", t("nw.footer.menu.investments"), '/about/investments')}
                  {SubMenuItem("about_transparency", t("nw.footer.menu.transparency"), '/about/transparency')}
                  {SubMenuItem("about_sustainability", t("nw.footer.menu.sustainability"), '/about/sustainability')}
                </Menu>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  </>;
}

