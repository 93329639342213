import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

import {
  Box,
  Skeleton
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
// import {
//   Typography
// } from '../styled';

// import { Benefit as Ben } from '../../../models/Benefit';


type Props = {};

export const Benefit: FunctionComponent<Props> = ({}) => {
  // const theme = useTheme();
  // const {t, i18n} = useTranslation(['translations']);
  // const navigate = useNavigate();

  return (<>
    <Box
      sx={{
        position: 'relative',
        zIndex: 1200,
      }}
    >
      <Skeleton sx={{
        width: '100%',
        height: '240px',
      }} />

      <Skeleton sx={{
        height: '72px',
      }} />
    </Box>
  </>);
}