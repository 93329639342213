import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { loginAtom } from '../../lib/auth';
import { backdropAtom } from '../../lib/backdrop';

import {
  Backdrop,
  Box
} from '@mui/material';

// import CloseIcon from '@mui/icons-material/Close';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import MenuIcon from '@mui/icons-material/Menu';

// import {
//   Typography
// } from '../../components/v2/styled';
import { SharedHeader } from './Header';

// const { DateTime } = require("luxon");

type Props = {
  sx?: object;
  children?: React.ReactNode;
  loginId?: number;
}

export const SharedLayout: FunctionComponent<Props> = ({sx, children}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const [currentBackdrop, setCurrentBackdrop] = useAtom(backdropAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const nonmobile = useMediaQuery('(min-width:600px)');

  const [locale, setLocale] = useState<string>(i18n.resolvedLanguage || 'nl');

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale]);

  return <Box sx={{
    // display: 'flex',
    // flexWrap: 'wrap',
    height: '100%',
    // alignContent: 'baseline',
    // backgroundColor: 'white'
  }}>
  <style type="text/css">{` 
hml,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: clip;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.scrollable-content {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100%;
  overflow: hidden;
}

.scrollable-content__content {
  max-width: 36rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-content__images {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.scrollable-content__images__image {
  border-radius: 9999px;
  background-color: #ff00ff;
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  line-height: 7.5rem;
}
`}</style>


    <SharedHeader drawerOpen={menuVisible} setDrawerOpen={setMenuVisible} />
    {!menuVisible && <Box sx={{
      flexDirection: "column",
      display: "flex",
      flexGrow: 1,
      height: "100%",
      // maxHeight: "100vh",
      // overflowY: "scroll",
      // overflowX: "none",
      width: "100%",
      "-webkit-overflow-scrolling": "touch",
      // position: 'absolute',
      // zIndex: 999,
      // top: 0,
      // left: 0,
      // paddingTop: {
      //   xs: '56px',
      //   md: '70px'
      // },
      // padding: theme.spacing(3)
      "&>*": {
        minWidth: 300,
        overflowX: "hidden"
      }
    }} id="scroller">
      {children}
    </Box>}

    <Backdrop
      sx={{ color: '#fff', zIndex: 1500 }}
      open={!!currentBackdrop}
      onClick={() => {
        setCurrentBackdrop(undefined);
      }}
    >
      <img style={{maxWidth: '80%', width: '80%'}} src={currentBackdrop} />
    </Backdrop>
  </Box>;
}

