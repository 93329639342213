import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Container
} from '@mui/material';

type Props = {
  sx?: object;
  children?: any;
};

export const Cta: FunctionComponent<Props> = ({sx, children}) => {
  const theme = useTheme();

  return (<Box sx={{
    width: "100%",
    paddingBottom: {
      xs: 5,
      md: 10,
      lg: 10
    },
    paddingTop: {
      xs: 5,
      md: 10,
      lg: 10
    },
    paddingLeft: {
      xs: 2.5,
      md: 10,
      lg: 10
    },
    paddingRight: {
      xs: 2.5,
      md: 10,
      lg: 10
    },
    borderRadius: '16px',
    backgroundColor: theme.palette.secondary.light,
    position: 'relative',
    ...sx
  }}>
    <Container fixed>
      {children}
    </Container>
  </Box>);
}