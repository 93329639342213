import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  emailCode?: string;
  setEmailCode: (s:string) => void;
  sentEmail?: string;
  onBack: () => void;
  onSubmit: () => void;
}

export const FirstTimeEmailCode: FunctionComponent<Props> = ({emailCode, setEmailCode, sentEmail, onBack, onSubmit}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h1" sx={{
        marginTop: 4,
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.register.hero.title_email_code")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.register.hero.subsection_email_code", {email: sentEmail})}</Typography>
    </Grid>

    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Box sx={{
        display: 'inline-block',
        width: {
          xs: '100%',
          lg: '440px'
        },
        textAlign: 'left'
      }}>
        <TextInput
          value={emailCode || ''}
          onChange={(v) => {
            setEmailCode(v);
          }}
          starred
          caption={t("nw.register.form.email_code")}
          // placeholder={t("nw.contact.form.placeholder.first_name")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Grid>

    <Grid item xs={12} sx={{textAlign: 'right', marginBottom: 8}}>
      <Box sx={{
        display: 'block',
        width: '100%',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        },
        marginTop: 2,
        marginRight: 'auto',
        marginLeft: 'auto',
        textAlign: 'right'
      }}>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '49%'
          },
          textAlign: 'left',
          float: 'left'
        }}>
          <TextButton
            label={t("nw.register.hero.cta_back")}
            id="sub_btn"
            onClick={() => {
              onBack();
            }}
            sx={{
              marginBottom: 1,
              color: theme.palette.primary.dark,
              textDecoration: 'underline',
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: 'transparent'
              },
              paddingLeft: 0,
              paddingRight: 0
            }}
          />
        </Box>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '49%'
          },
          marginLeft: 'auto',
          textAlign: 'right'
        }}>
          <DarkGreenButton
            label={t("nw.register.hero.cta")}
            id="sub_btn"
            sx={{
              marginBottom: 1,
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
              "&.Mui-disabled": {
                color: 'grey',
              },
            }}
            disabled={!emailCode}
            onClick={() => {
              onSubmit();
            }}
          />
        </Box>
      </Box>
    </Grid>
  </>;
}

