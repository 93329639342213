import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import LogoWhite from '../../../img/Argenco_logo_white_rgb.svg';
// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_outline_eco_rgb.svg";

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

import {
  IconButton,
  Typography,
  WhiteButton
} from '../styled';
import { useNavigate } from 'react-router-dom';

type Props = {
  sx?: object;
  normalPage?: boolean;
};

export const Footer: FunctionComponent<Props> = ({sx, normalPage}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const footerLink = (label:string, goto:string) => {
    return (<Typography
      variant="body2"
      sx={{
        color: theme.palette.primary.light,
        marginBottom: 1,
        cursor: 'pointer'
      }}
      onClick={() => {
        navigate(goto);
        window.scrollTo(0, 0);
      }}
    >{label}</Typography>);
  }
  const footerText = (label:string) => {
    return (<Typography
      variant="body2"
      sx={{
        color: theme.palette.primary.light,
        marginBottom: 1
      }}
    >{label}</Typography>);
  }
  const footerTitle = (label:string) => {
    return (<Typography
      variant="body1"
      // html
      sx={{
        color: 'white',
        marginBottom: 2
      }}
    >{label}</Typography>);
  }

  return (<Box sx={{
    width: "calc(100% + 48px)",
    marginLeft: -3,
    marginTop: !!normalPage ? 0 : -3,
    marginBottom: !!normalPage ? 0 : -3,
    marginRight: -3,
    // maxHeight: '100vh',
    padding: {
      xs: 1,
      md: 3,
      lg: 3
    },
    paddingTop: {
      xs: !!normalPage ? 1.25 : 11,
      md: !!normalPage ? 1.25 : 13,
      lg: !!normalPage ? 1.25 : 13,
    },
    paddingBottom: !!normalPage ? 1.25 : 6,
    backgroundColor: !!normalPage ? 'transparent' : 'white',
    position: 'relative',
    zIndex: 920,
    ...sx
  }}>
    <Container fixed sx={{overflow: 'hidden'}}>
      <Box sx={{
        backgroundColor: theme.palette.primary.main,
        padding: {
          xs: 4,
          md: 6,
          lg: 6
        },
        borderRadius: '16px'
      }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <img src={LogoWhite} style={{width: '200px'}} />
          </Grid>
          <Grid container spacing={0} item xs={12} sx={{marginTop: 6}}>
            <Grid item xs={6} md={3} lg={2}>
              {footerTitle(t("nw.footer.title.argenco"))}
              {footerLink(t("nw.footer.menu.benefits"), "/benefits")}
              {footerLink(t("nw.footer.menu.faq"), "/faq")}
              {footerLink(t("nw.footer.menu.news"), "/news")}
              {footerLink(t("nw.footer.menu.contact"), "/contact")}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              {footerTitle(t("nw.footer.title.invest"))}
              {footerLink(t("nw.footer.menu.invest_why"), "/stock/why")}
              {footerLink(t("nw.footer.menu.invest_how"), "/stock/how")}
              {footerLink(t("nw.footer.menu.transfer"), "/stock/transfer")}
              {footerLink(t("nw.footer.menu.dividends"), "/stock/dividend")}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              {footerTitle(t("nw.footer.title.about"))}
              {footerLink(t("nw.footer.menu.mission_values"), "/about/mission")}
              {footerLink(t("nw.footer.menu.team_rvb"), "/about/team")}
              {footerLink(t("nw.footer.menu.history"), "/about/history")}
              {footerLink(t("nw.footer.menu.investments"), "/about/investments")}
              {footerLink(t("nw.footer.menu.transparency"), "/about/transparency")}
              {footerLink(t("nw.footer.menu.sustainability"), "/about/sustainability")}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              {footerTitle(t("nw.footer.title.documents"))}
              {footerLink(t("nw.footer.menu.prospectus"), "/")}
              {footerLink(t("nw.footer.menu.statuten"), "/")}
              {footerLink(t("nw.footer.menu.rules"), "/")}
              {footerLink(t("nw.footer.menu.yearly_report"), "/")}
              {footerLink(t("nw.footer.menu.disclaimer"), "/")}
              {footerLink(t("nw.footer.menu.privacy"), "/")}
            </Grid>
            <Grid item xs={12} lg={2}>
              
            </Grid>
            <Grid item xs={6} md={6} lg={2}>
              {footerTitle(t("nw.footer.title.contact"))}
              {footerText(t("nw.footer.text.address_name"))}
              {footerText(t("nw.footer.text.address_line1"))}
              {footerText(t("nw.footer.text.address_line2"))}
              <Typography variant="body1" sx={{color: theme.palette.primary.light}}>&nbsp;</Typography>
              {footerText(t("nw.footer.text.phone"))}
              {footerText(t("nw.footer.text.email"))}
              <WhiteButton
                label={t("nw.footer.button.contact_us")}
                id="nw.footer.button.contact_us"
                size="small"
                sx={{
                  marginTop: 1,
                  fontSize: '14px'
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={12} sx={{marginTop: 10}}>
            <Grid item xs={12}>
              <IconButton onClick={() => {
                window.open("https://www.facebook.com/argencocv", "_BLANK");
              }}>
                <FacebookIcon sx={{color: theme.palette.primary.light}} />
              </IconButton>
              <IconButton onClick={() => {
                window.open("https://www.linkedin.com/company/argen-co/", "_BLANK");
              }}>
                <LinkedInIcon sx={{color: theme.palette.primary.light}} />
              </IconButton>
              <IconButton onClick={() => {
                window.open("https://www.instagram.com/argencocv/", "_BLANK");
              }}>
                <InstagramIcon sx={{color: theme.palette.primary.light}} />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{position: 'relative'}}>
              {footerTitle(t("nw.footer.title.copyright"))}
              <Box sx={{
                display: {
                  xs: 'none',
                  md: 'flex'
                },
                position: 'absolute',
                bottom: {
                  md: -60,
                  lg: -100
                },
                left: {
                  md: '50%',
                  lg: '60%'
                },
                "& svg": {
                  transform: {
                    md: "scale(1.8)",
                    lg: "scale(1.2)"
                  },
                  width: '15vw'
                },
              }}><Arrow /></Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>);
}