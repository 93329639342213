import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import {
  IconButton as IB
} from '@mui/material';
// import { Removal } from '../dialogs/Removal';

// import { loginAtom } from '../../../lib/auth';

type Props = {
  sx?: object;
  color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  title?: string;
  path?: string;
  newTab?: boolean;
  disabled?: boolean;
  onClick?: (e:React.MouseEvent, f?:boolean) => void;
  children: React.ReactNode;
};

export const IconButton: FunctionComponent<Props> = ({disabled, newTab, sx, color, onClick, path, children, title}) => {
  // const theme = useTheme();
  const navigate = useNavigate();

  // const [login, setLogin] = useAtom(loginAtom);

  // const [open, setOpen] = useState(false);
  const [ctrlActive, setCtrlActive] = useState(false);

  // let setting_ask_destroy_confirm_raw = '0';
  // let setting_ignore_destroy_confirm = setting_ask_destroy_confirm_raw === "1";

  return (<>
    <IB
      sx={sx}
      color={color}
      title={title}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1 || !!newTab) {
            let handle = window.open(path, "_BLANK");
            if (!!handle) handle.blur();
          } else {
            if (!!path) navigate(path);
          }
          setCtrlActive(false);
        }
        if (!!onClick) {
          onClick(e);
          // if (!!destroyBtn && !setting_ignore_destroy_confirm) {
          //   setOpen(true);
          // } else {
          //   onClick(e);
          // }
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) {
          let handle = window.open(path, "_BLANK");
          if (!!handle) handle.blur();
        }
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) {
            let handle = window.open(path, "_BLANK");
            if (!!handle) handle.blur();
          }
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
    >
      {children}
    </IB>

    {/* {!!destroyBtn && <Removal
      open={open}
      setOpen={setOpen}
      execute={(e) => {
        if (!!onClick) onClick(e);
        setOpen(false);
      }}
    />} */}
  </>);
}