import React, { FunctionComponent, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  
}

export const EmissionLoader: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();






  useEffect(() => {
    navigate("/emission", {state: {step: 8, sub: params.sub}});
  }, []);
  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      {/* <Typography variant="h1" sx={{
        marginTop: 4,
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.emission.hero.title")}</Typography> */}
      <Box sx={{height: 100}} />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.emission.itsme_connecting")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <CircularProgress />
    </Grid>
  </>;
}

