import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

// import {
//   Box,
//   Container
// } from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  DarkGreenButton,
  TextInput
} from '../styled';

type Props = {
  sx?: object;
  placeholder: string;
  value?: string;
  setValue: (s:string) => void;
  btnLabel: string;
  onClick: () => void;
};

export const InputButton: FunctionComponent<Props> = ({sx, value, setValue, btnLabel, onClick, placeholder}) => {
  const theme = useTheme();

  return (<>
    <TextInput
      value={value || ''}
      onChange={(v) => {
        setValue(v);
      }}
      onKeyDown={(e) => {
        if(e.keyCode == 13){
          onClick();
       }
      }}
      placeholder={placeholder}
      sx={{
        borderRadius: {
          xs: "8px",
          md: "8px 0px 0px 8px"
        },
        width: {
          xs: '100%',
          md: "300px"
        },
        border: "1px solid transparent",
        borderColor: theme.palette.primary.dark,
        paddingTop: 0.25,
        paddingBottom: 0.25,
        height: '40px',
        backgroundColor: 'transparent',
        "&:has(+ button:hover)": {
          borderColor: theme.palette.primary.light
        },
        ...sx
      }}
    />
    <DarkGreenButton
      label={btnLabel}
      id="inputbtn"
      onClick={() => {
        onClick();
      }}
      sx={{
        borderRadius: {
          xs: "8px",
          md: "0px 8px 8px 0px"
        },
        height: '40px',
        marginTop: {
          xs: 2,
          md: "-3px"
        }
      }}
    />
  </>);
}