import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import {unregister} from './registerServiceWorker';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
// import { Provider } from 'jotai';

// import i18next from './i18n';

import {Main} from './pages/Main';
import {Loader} from './components/v2/Loader';

// import { LicenseInfo } from "@mui/x-license-pro";
// LicenseInfo.setLicenseKey(
//   "993f1d8406233c77c2eaf9db599f07f7Tz05NzgwOCxFPTE3NTc1NDM1MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
// );

const { Settings } = require("luxon");

const queryClient = new QueryClient();
Settings.defaultLocale = "nl";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
    // fontFamily: ['PPFormula', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 800,

    h1: {
      fontSize: 40,
      fontWeight: 800,
      lineHeight: '48px',
      color: '#185459',
      textTransform: 'uppercase'
      // color: 'rgb(16, 46, 86)'
    },
    h2: {
      fontSize: 28,
      fontWeight: 800,
      lineHeight: '33.6px',
      color: '#185459',
      textTransform: 'uppercase'
      // color: 'rgb(16, 46, 86)'
    },
    h3: {
      fontSize: 20,
      fontWeight: 800,
      lineHeight: '24px',
      color: '#185459',
      textTransform: 'uppercase'
      // color: 'rgb(16, 46, 86)'
    },
    h4: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: '21.6px',
      color: '#185459',
      textTransform: 'uppercase'
      // color: 'rgb(16, 46, 86)'
    },
    h5: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '19.2px',
      color: '#185459',
      textTransform: 'uppercase'
    },
    h6: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: '22.4px',
      color: '#185459',
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontSize: 18,
      // color: 'rgb(16, 46, 86)'
      lineHeight: '28.8px',
      color: '#185459',
    },
    body1: {
      fontSize: 16,
      // color: 'rgb(16, 46, 86)'
      lineHeight: '25.6px',
      color: '#185459',
    },
    body2: {
      fontSize: 14,
      // color: 'rgb(16, 46, 86)'
      lineHeight: '22.4px',
      color: '#185459',
    }
  },
  palette: {
    primary: {
      light: '#57bf93',
      main: '#185459',
      dark: '#0c280e',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#a9c07f', // fade green
      light: '#e1e8df', // muted green,
      dark: '#6fcadf', // blue
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#E69E54'
    },
    mode: 'light',
    background: {
      default: "#f9faf9",
      // lighter: "#ffffff"
    },
  },
  shape: {
    borderRadius: 18
  },
  // drawer_width: 240,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          fontFamily: ['Work Sans', 'sans-serif'].join(','),
          fontWeight: 400
        })
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: ['PPFormula', 'sans-serif'].join(','),
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: ['PPFormula', 'sans-serif'].join(','),
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            fontFamily: ['PPFormula', 'sans-serif'].join(','),
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: ['PPFormula', 'sans-serif'].join(','),
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: ['PPFormula', 'sans-serif'].join(','),
          },
        },
      ]
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: 12,
          paddingBottom: 12
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <React.Suspense fallback={(<span>Please wait...</span>)}>
    {/* <Provider> */}
        <Loader />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeProvider theme={defaultTheme}>
              <Main />
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
    {/* </Provider> */}
      </React.Suspense>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
