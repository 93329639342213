import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../lib/v31lib";
import do_login, { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
import {
  WhiteSection
} from '../../components/v2/custom';
import {
  Page,
} from '../../components/v2/styled';
import { FirstTimeValidation } from './first_time/Validation';
import { FirstTimeEmailCode } from './first_time/EmailCode';
import { FirstTimeSecQuestions } from './first_time/SecQuestions';
import { FirstTimeError } from './first_time/Error';
import { FirstTimeCredentials } from './first_time/Credentials';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Register: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [step, setStep] = useState<number>(0);

  const [vennr, setVennr] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [showMfaField, setShowMfaField] = useState<boolean>(false);
  const [mfaCode, setMfaCode] = useState<string>('');

  const [sentEmail, setSentEmail] = useState<string>('');
  const [emailCode, setEmailCode] = useState<string>('');

  const [secQuestion1, setSecQuestion1] = useState<string>('');
  const [secQuestion1Answer, setSecQuestion1Answer] = useState<string>('');
  const [secQuestion2, setSecQuestion2] = useState<string>('');
  const [secQuestion2Answer, setSecQuestion2Answer] = useState<string>('');
  const [secQuestion3, setSecQuestion3] = useState<string>('');
  const [secQuestion3Answer, setSecQuestion3Answer] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [existing, setExisting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  

  // STEP
  // 0 => start
  // options: (2FA + SecQ) || EmailCode || GoFuckethThyneself
  // 5 => sec q
  // 15 => email_code
  // 50 => credentials
  // 404 => error






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      step: number;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);

      if (variables.formData.mode === 'validate') {
        if (!!data.custom_result.exists) {
          if (!!data.custom_result.mfa_status_0_verification) {
            setShowMfaField(true);
          } else {
            if (!!data.custom_result.sec_q_status) {
              setSecQuestion1(data.custom_result.sec_q_q1);
              setSecQuestion2(data.custom_result.sec_q_q2);
              setSecQuestion3(data.custom_result.sec_q_q3);
              setStep(5);
            } else {
              if (!!data.custom_result.email_verification) {
                setSentEmail(data.custom_result.email);
                setStep(15);
              } else {
                setUsername(data.custom_result.username);
                setStep(50);
              }
            }
          }
        } else {
          // error
          setStep(404);
        }
      }
      if (variables.formData.mode === 'credentials') {
        if (!!data.custom_result.success) {
          do_login(username, password, (result) => {
            setLogin(result);
            navigate("/profile");
          });
        } else {
          setExisting(data.custom_result.message === 'invalid_username');
          setMessage(data.custom_result.message);
        }
      }
    },
  });








  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5} sx={{
        marginTop: {
          xs: 2,
          lg: 4
        }
      }}>
        {step === 0 && <FirstTimeValidation
          birthdate={birthdate}
          setBirthdate={setBirthdate}
          vennr={vennr}
          setVennr={setVennr}
          onBack={() => {
            navigate("/");
          }}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_first_time',
                mode: 'validate',
                ob: {
                  birth_date: birthdate,
                  vennr: vennr
                },
                testing: 1,
                ...(!!showMfaField ? {
                  otp_code: mfaCode
                } : {})
              },
              step: step
            });
          }}
          showMfaField={showMfaField}
          mfaCode={mfaCode}
          setMfaCode={setMfaCode}
        />}
        {step === 5 && <FirstTimeSecQuestions
          secQuestion1={secQuestion1}
          secQuestion1Answer={secQuestion1Answer}
          setSecQuestion1Answer={setSecQuestion1Answer}
          secQuestion2={secQuestion2}
          secQuestion2Answer={secQuestion2Answer}
          setSecQuestion2Answer={setSecQuestion2Answer}
          secQuestion3={secQuestion3}
          secQuestion3Answer={secQuestion3Answer}
          setSecQuestion3Answer={setSecQuestion3Answer}
          onBack={() => {
            setStep(0);
            setEmailCode('');
            setMfaCode('');
            setSecQuestion1Answer('');
            setSecQuestion2Answer('');
            setSecQuestion3Answer('');
          }}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_first_time',
                mode: 'validate',
                ob: {
                  birth_date: birthdate,
                  vennr: vennr
                },
                testing: 1,
                ...(!!showMfaField ? {
                  otp_code: mfaCode
                } : {}),
                sec_q: {
                  answer_1: secQuestion1Answer,
                  answer_2: secQuestion2Answer,
                  answer_3: secQuestion3Answer
                }
              },
              step: step
            });
          }}
        />}
        {step === 15 && <FirstTimeEmailCode
          emailCode={emailCode}
          setEmailCode={setEmailCode}
          sentEmail={sentEmail}
          onBack={() => {
            setStep(0);
            setEmailCode('');
            setMfaCode('');
            setSecQuestion1Answer('');
            setSecQuestion2Answer('');
            setSecQuestion3Answer('');
          }}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_first_time',
                mode: 'validate',
                ob: {
                  birth_date: birthdate,
                  vennr: vennr
                },
                testing: 1,
                ...(!!showMfaField ? {
                  otp_code: mfaCode
                } : {}),
                email_code: emailCode
              },
              step: step
            });
          }}
        />}
        {step === 50 && <FirstTimeCredentials
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          passwordRepeat={passwordRepeat}
          setPasswordRepeat={setPasswordRepeat}
          message={message}
          existing={existing}
          onBack={() => {
            setStep(0);
            setEmailCode('');
            setMfaCode('');
            setSecQuestion1Answer('');
            setSecQuestion2Answer('');
            setSecQuestion3Answer('');
          }}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_first_time',
                mode: 'credentials',
                ob: {
                  birth_date: birthdate,
                  vennr: vennr
                },
                username: username,
                password: password,
                password_repeat: passwordRepeat
              },
              step: step
            });
          }}
        />}
        {step === 404 && <FirstTimeError
          onBack={() => {
            setStep(0);
            setEmailCode('');
            setMfaCode('');
            setSecQuestion1Answer('');
            setSecQuestion2Answer('');
            setSecQuestion3Answer('');
          }}
        />}
        
      </Grid>
    </WhiteSection>
  </Page>;
}

