import { atom } from "jotai";
import { fetch_one } from "./server_helper_new";
import { getCookie, setCookie } from 'react-use-cookie';

import { LOGIN_URL, RENEW_URL, LOGOUT_URL, loginFields, User } from '../models/User';

export const loginAtom = atom<User|undefined>(undefined);

export default function do_login(username: string, password: string, cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: loginFields,
    username: username,
    password: password,
    priv: 1,
    is_www: 1
  }, (x, y) => {
    setCookie('argenco_www_token', x?.auth_token);
    setCookie('argenco_www_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  });
}

export function do_login_otp(username: string, password: string, otp: string, cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: loginFields,
    username: username,
    password: password,
    otp: otp,
    priv: 1,
    is_www: 1
  }, (x, y) => {
    setCookie('argenco_www_token', x?.auth_token);
    setCookie('argenco_www_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  });
}

// export function do_login_slug(slug: string, cb:(z:User|undefined) => void) {
//   fetch_one<User>(`/nl${LOGIN_URL}`, {
//     fields: loginFields,
//     slug: slug,
//     priv: 1
//   }, (x, y) => {
//     setCookie('nutriportal_token', x?.auth_token);
//     setCookie('nutriportal_email', x?.email);
//     cb(x);
//     return x;
//   }, (x) => {
//     cb(undefined);
//   });
// }
//
// export function do_login_magic_link(magic_link: string, cb:(z:User|undefined) => void) {
//   fetch_one<User>(`/nl${LOGIN_URL}`, {
//     fields: loginFields,
//     magic_link: magic_link,
//     priv: 1
//   }, (x, y) => {
//     setCookie('nutriportal_token', x?.auth_token);
//     setCookie('nutriportal_email', x?.email);
//     cb(x);
//     return x;
//   }, (x) => {
//     cb(undefined);
//   });
// }

export function do_logout(cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${LOGOUT_URL}`, {
    fields: [],
    priv: 1,
    is_www: 1
  }, (x, y) => {
    setCookie('argenco_www_token', '');
    setCookie('argenco_www_email', '');
    cb(undefined);
  }, (x) => {
    setCookie('argenco_www_token', '');
    setCookie('argenco_www_email', '');
    cb(undefined);
  }, {auth_token: getCookie('argenco_www_token'), email: getCookie('argenco_www_email')});
}

export function set_login(email: string, auth_token: string) {
  setCookie('argenco_www_token', auth_token);
  setCookie('argenco_www_email', email);
}

export function renew_sign_in(cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${RENEW_URL}`, {
    fields: loginFields,
    priv: 1,
    is_www: 1
  }, (x, y) => {
    cb(x);
    return x;
  }, (x) => {
    //@ts-ignore
    if (x.data === 'not_authenticated') {
      setCookie('argenco_www_token', '');
      setCookie('argenco_www_email', '');
      window.location.href = "/";
    }
    cb(undefined);
  }, {auth_token: getCookie('argenco_www_token'), email: getCookie('argenco_www_email')});
}
