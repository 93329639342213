import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../img/Argenco_pijl_green_rgb.svg";

import {
  Box,
  Divider,
  Grid,
  Stack,
} from '@mui/material';

import {
  Benefit as BenTile,
  Cta,
  Footer,
  Hero,
  Section,
} from '../../components/v2/custom';
import {
  IconButton,
  LightGreenButton,
  Page,
  TextButton,
  TransparentButton,
  Typography
} from '../../components/v2/styled';

import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const BenefitDetail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();

  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [benefit, setBenefit] = useState<Ben>();

  const {
    data: pubBenefit,
    isLoading: pubBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: pubBenefitSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "pub", "benefit", objectId],
    queryFn: () =>
      fetch_one<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'nw_intro', 'discount_name', 'get_item'],
          id: objectId
        },
        undefined
      ),
    enabled: !!objectId && !login,
  });
  const {
    data: fullBenefit,
    isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: fullBenefitSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "pub", "benefit", objectId],
    queryFn: () =>
      fetch_one<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'nw_intro', 'discount_name', 'get_item'],
          id: objectId
        },
        login
      ),
    enabled: !!objectId && !!login,
  });

  const {
    data: benefits,
    // isLoading: adviceUploadsLoading,
    // isError: adviceUploadsError,
    isSuccess: benefitsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "rand_benefits", objectId],
    queryFn: () =>
      fetch_all<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'discount_name', 'get_item'],
          per_page: 3,
          order: 'RAND() ASC',
          filter: {
            advanced: {
              enabled: 1,
              skip_id: objectId
            }
          }
        },
        undefined
      ),
    enabled: !!objectId,
  });








  const tableRow = (label: string, content: string, divider: boolean) => {
    return <>
      <Grid item xs={4} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }}>
        <Typography sx={{
          textTransform: 'uppercase',
          color: theme.palette.primary.light
        }}>{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }}>
        <Typography variant="body1">{content}</Typography>
      </Grid>

      {!!divider && <Grid item xs={12}>
        <Divider />
      </Grid>}
    </>;
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!pubBenefit) setBenefit(pubBenefit);
  }, [pubBenefit]);
  useEffect(() => {
    if (!!fullBenefit) setBenefit(fullBenefit);
  }, [fullBenefit]);
  useEffect(() => {
    setObjectId(params.id);
  }, [params.id]);

  return <Page>
    {!!benefit && <Hero
      children={<>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{(benefit.discount_name || {})[i18n.resolvedLanguage || 'nl']}</Typography>
        {!!(benefit.nw_intro || {})[i18n.resolvedLanguage || 'nl'] && <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{(benefit.nw_intro || {})[i18n.resolvedLanguage || 'nl']}</Typography>}
      </>}
    />}

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <IconButton sx={{
            "& svg": {
              transform: "rotate(270deg)",
              width: '20px',
              marginTop: -1,
              marginLeft: -0.25
            },
            display: 'inline-block',
            marginRight: 'auto',
            height: '32px',
            width: '32px',
            paddingTop: '5px',
            backgroundColor: theme.palette.secondary.light,
          }} onClick={() => {
            navigate('/benefits');
          }}>
            <Arrow />
          </IconButton>
          <TextButton
            id="back_btn"
            label={t("nw.benefit.detail.back_cta")}
            onClick={() => {
              navigate('/benefits');
            }}
            sx={{
              fontSize: '16px',
              textDecoration: 'underline'
            }}
          />
        </Grid>
      </Grid>
    </Section>

    <Section normalPage>
      {!login && <Cta sx={{
        position: 'absolute',
        top: 300,
        left: 0,
        zIndex: 1101
      }}>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.section.not_logged_in.title")}</Typography>
        <Typography sx={{
          marginTop: {
            xs: 2,
            md: 3
          },
          textAlign: 'center'
        }} variant="body1" html fuck_p_tags>{t("nw.section.not_logged_in.text")}</Typography>

        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <LightGreenButton
            id="sharing_login"
            label={t("nw.stock.transfer.sharing.cta_login")}
            onClick={() => {
              navigate("/login");
            }}
          />
          <TransparentButton
            id="sharing_register"
            label={t("nw.stock.transfer.sharing.cta_register")}
            sx={{
              marginLeft: {
                xs: 0,
                md: 2.5
              },
              marginTop: {
                xs: 2.5,
                md: 0
              }
            }}
            onClick={() => {
              navigate("/first_time");
            }}
          />
        </Box>
      </Cta>}
      <Grid container spacing={2.5} sx={{
        filter: !login ? 'blur(4px)' : 'blur(0)',
        position: 'relative',
        zIndex: 1100
      }}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '100%',
                lg: '670px'
              },
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'white'
            }}
          >
            <Grid container spacing={0}>
              {!login && tableRow(t("nw.benefit.detail.table.what"), "Lorem ipsum", true)}
              {!login && tableRow(t("nw.benefit.detail.table.where"), "Sit ipsum dolor lorem amet", true)}
              {!login && tableRow(t("nw.benefit.detail.table.when"), "Lorem ipsum dolor", true)}
              {!login && tableRow(t("nw.benefit.detail.table.how"), "Quandicus delorium dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet", false)}
              {!!login && tableRow(t("nw.benefit.detail.table.what"), "Lorem ipsum", true)}
              {!!login && tableRow(t("nw.benefit.detail.table.where"), "Lorem ipsum dolor sit amet", true)}
              {!!login && tableRow(t("nw.benefit.detail.table.when"), "Lorem ipsum dolor", true)}
              {!!login && tableRow(t("nw.benefit.detail.table.how"), "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet", false)}
            </Grid>
          </Box>
        </Grid>

        {!login && <Grid item xs={12} sx={{height: '300px'}}>
          {/* spacer */}
        </Grid>}

        {!!benefit?.get_item && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <img src={benefit?.get_item} style={{maxWidth: '400px'}} />
        </Grid>}
      </Grid>
    </Section>

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center', marginBottom: 4, marginTop: 8}}>{t("nw.benefit.detail.more_benefits_title")}</Typography>
          <Typography variant="subtitle1" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.benefit.detail.more_benefits_text")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{
            overflowX: 'auto',
          }}>
            {benefitsSuccess && benefits.map(benefit => <BenTile benefit={benefit} sx={{
              minWidth: {xs: '99%', md: '49%', lg: '32%'},
              maxWidth: {xs: '99%', md: '49%', lg: '32%'}
            }} />)}
          </Stack>
        </Grid>
      </Grid>
    </Section>

    <Footer normalPage />
  </Page>;
}

