import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  CircularProgress,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
  user?: User;
}

export const EmissionChoiceMeNewItsme: FunctionComponent<Props> = ({setStep, user}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_personal")}</Typography>
    </Grid>
    {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.subsection")}</Typography>
    </Grid> */}
    <Grid item xs={5}>
      <TextInput
        value={user?.first_name || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.first_name")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={7}>
      <TextInput
        value={user?.last_name || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.last_name")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={7}>
      <TextInput
        value={user?.birth_date || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.birth_date")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={5}>
      gender
    </Grid>
    <Grid item xs={12}>
      error too young
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.email || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.email")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.repeat_email || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.repeat_email")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.phone || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.phone")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_address")}</Typography>
    </Grid>
    <Grid item xs={8}>
      <TextInput
        value={user?.street || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.street")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        value={user?.number || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.number")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        value={user?.zip || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.zip")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={8}>
      <TextInput
        value={user?.city || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.city")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.zicht_account || ''}
        onChange={(v) => {
          
        }}
        starred
        disabled
        caption={t("nw.user_info.zicht_account")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>

    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
      <DarkGreenButton
        id="next_btn"
        label={t("nw.emission.next")}
        onClick={() => {
          // onSubmit();
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
      <TextButton
        id="back_btn"
        label={t("nw.emission.forgot.cta_back")}
        onClick={() => {
          setStep(7);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
  </>;
}

