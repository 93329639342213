import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  TextButton,
  TextInput,
  TransparentButton,
  Typography
} from '../../../components/v2/styled';
import { OtpCode } from '../../../components/v2/styled/OtpCode';
import { Vennr } from '../../../components/v2/styled/Vennr';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
  username: string;
  setUsername: (s:string) => void;
  password: string;
  setPassword: (s:string) => void;
  vennr: string;
  setVennr: (s:string) => void;
  birthdate: string;
  setBirthdate: (s:string) => void;
  otp: string;
  setOtp: (s:string) => void;
  showOtp: boolean;
  onSubmit: () => void;
}

export const EmissionChoiceMeExisting: FunctionComponent<Props> = ({birthdate, setBirthdate, vennr, setVennr, setStep, username, setUsername, password, setPassword, otp, setOtp, showOtp, onSubmit}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [useVennr, setUseVennr] = useState<boolean>(true);





  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h1" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.title")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.subsection")}</Typography>
    </Grid>


    {/* <Grid item xs={6} sx={{textAlign: 'center', marginTop: {
      xs: 2,
      md: 4
    }}}>
      <TransparentButton
        id="pick_vennoot"
        label={t("nw.emission.choice_me.pick_vennr")}
        onClick={() => {
          setUseVennr(true);
        }}
      />
    </Grid>
    <Grid item xs={6} sx={{textAlign: 'center', marginTop: {
      xs: 2,
      md: 4
    }}}>
      <TransparentButton
        id="pick_username"
        label={t("nw.emission.choice_me.pick_username")}
        onClick={() => {
          setUseVennr(false);
        }}
      />
    </Grid> */}


    {useVennr && <>
      <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '440px'
          },
          textAlign: 'left'
        }}>
          <Vennr
            value={vennr || ''}
            onChange={(v) => {
              setVennr(v.target.value);
            }}
            starred
            caption={t("nw.register.form.lidnummer")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
          <TextInput
            value={birthdate || ''}
            onChange={(v) => {
              setBirthdate(v);
            }}
            starred
            caption={t("nw.register.form.birth_date")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            type="date"
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
          {!!showOtp && <OtpCode
            value={otp || ''}
            onChange={(v) => {
              setOtp(v.target.value);
              if (parseInt(v.target.value.replace(" ", ""), 10).toString().length === 6) {
                onSubmit();
              }
            }}
            starred
            caption={t("nw.public.otp_code")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />}
        </Box>
      </Grid>
    </>}

    {!useVennr && <>
      <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '440px'
          },
          textAlign: 'left'
        }}>
          <TextInput
            value={username}
            onChange={(v) => {
              setUsername(v);
            }}
            starred
            caption={t("nw.login.form.username")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
          <TextInput
            value={password}
            onChange={(v) => {
              setPassword(v);
            }}
            starred
            caption={t("nw.login.form.password")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            type="password"
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
          {!!showOtp && <OtpCode
            value={otp || ''}
            onChange={(v) => {
              setOtp(v.target.value);
              if (parseInt(v.target.value.replace(" ", ""), 10).toString().length === 6) {
                onSubmit();
              }
            }}
            starred
            caption={t("nw.public.otp_code")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />}
        </Box>
      </Grid>
    </>}

    <Grid item xs={12} sx={{textAlign: 'right', marginBottom: 8}}>
      <Box sx={{
        display: 'block',
        width: '100%',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        },
        marginTop: 2,
        marginRight: 'auto',
        marginLeft: 'auto',
        textAlign: 'right'
      }}>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '49%'
          },
          textAlign: 'left',
          float: 'left'
        }}>
          <TextButton
            label={t("nw.emission.forgot.cta_back")}
            id="sub_btn"
            onClick={() => {
              setStep(5);
              window.scrollTo(0, 0);
            }}
            sx={{
              marginBottom: 1,
              color: theme.palette.primary.dark,
              textDecoration: 'underline',
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: 'transparent'
              },
              paddingLeft: 0,
              paddingRight: 0
            }}
          />
        </Box>
        <Box sx={{
          display: 'inline-block',
          width: {
            xs: '100%',
            lg: '49%'
          },
          marginLeft: 'auto',
          textAlign: 'right'
        }}>
          <DarkGreenButton
            label={t("nw.register.hero.cta")}
            id="sub_btn"
            sx={{
              marginBottom: 1,
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
              "&.Mui-disabled": {
                color: 'grey',
              },
            }}
            disabled={!vennr || !birthdate || (!!showOtp && !otp)}
            onClick={() => {
              onSubmit();
            }}
          />
        </Box>
      </Box>
    </Grid>
  </>;
}

