import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Container
} from '@mui/material';

type Props = {
  sx?: object;
  dark?: boolean;
  children?: any;
  normalPage?: boolean;
};

export const Section: FunctionComponent<Props> = ({sx, dark, children, normalPage}) => {
  const theme = useTheme();

  return (<Box sx={{
    width: "calc(100% + 48px)",
    marginTop: !!normalPage ? 0 : -3,
    marginLeft: -3,
    marginRight: -3,
    // maxHeight: '100vh',
    padding: {
      xs: 1,
      md: 3,
      lg: 3
    },
    paddingTop: {
      xs: !!normalPage ? 1.25 : 13,
      md: !!normalPage ? 1.25 : 18,
      lg: !!normalPage ? 1.25 : 18,
    },
    paddingBottom: {
      xs: !!normalPage ? 1.25 : 10,
      md: !!normalPage ? 1.25 : 10,
      lg: !!normalPage ? 1.25 : 15
    },
    borderRadius: '0px 0px 16px 16px',
    backgroundColor: (!!dark ? theme.palette.secondary.light : theme.palette.background.default),
    position: 'relative',
    ...sx
  }}>
    <Container fixed>
      {children}
    </Container>
  </Box>);
}