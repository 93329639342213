import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  TextButton,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
}

export const EmissionLanding: FunctionComponent<Props> = ({setStep}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        textAlign: 'left',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.landing.fsma_disclaimer")}</Typography>
    </Grid>

    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <DarkGreenButton
        label={t("nw.emission.cta.buy_me")}
        id="buy_me"
        onClick={() => {
          setStep(5);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
      <LightGreenButton
        label={t("nw.emission.cta.buy_minor")}
        id="buy_minor"
        onClick={() => {
          setStep(15);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <TextButton
        label={t("nw.emission.cta.forgot_url")}
        id="forgot_url"
        onClick={() => {
          setStep(100);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
  </>;
}

