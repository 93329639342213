import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Footer,
  Section,
  WhiteSection
} from '../../../components/v2/custom';
import {
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutHistory: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  const historyBox = (year:number, title:string, content:string, isGreen?:boolean, hasTopGap?: boolean) => {
    return <Box sx={{
      width: '100%',
      display: 'inline-block',
      borderRadius: '10px',
      backgroundColor: !!isGreen ? theme.palette.secondary.light : 'white',
      margin: 0,
      marginTop: {
        xs: 0,
        md: !!hasTopGap ? 20 : 0
      },
      marginBottom: 2.5,
      padding: 4
    }}>
      <Typography variant="h2" sx={{color: theme.palette.primary.light}}>{year}</Typography>
      <Typography variant="h1" sx={{marginBottom: 4}}>{title}</Typography>
      <Typography variant="subtitle1">{content}</Typography>
    </Box>;
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h1" sx={{
            marginTop: 8,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.history.hero.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="subtitle1" html fuck_p_tags sx={{
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.history.hero.subsection")}</Typography>
        </Grid>
      </Grid>
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6} sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}>
          {historyBox(1956, t("nw.about.history.year1956.title"), t("nw.about.history.year1956.content"))}
          {historyBox(2015, t("nw.about.history.year2015.title"), t("nw.about.history.year2015.content"))}
        </Grid>
        <Grid item xs={12} md={6} sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}>
          {historyBox(2010, t("nw.about.history.year2010.title"), t("nw.about.history.year2010.content"), false, true)}
          {historyBox(2024, t("nw.about.history.year2024.title"), t("nw.about.history.year2024.content"), true)}
        </Grid>
        <Grid item xs={12} sx={{
          display: {
            xs: 'block',
            md: 'none'
          }
        }}>
          {historyBox(1956, t("nw.about.history.year1956.title"), t("nw.about.history.year1956.content"))}
          {historyBox(2010, t("nw.about.history.year2010.title"), t("nw.about.history.year2010.content"))}
          {historyBox(2015, t("nw.about.history.year2015.title"), t("nw.about.history.year2015.content"))}
          {historyBox(2024, t("nw.about.history.year2024.title"), t("nw.about.history.year2024.content"), true)}
        </Grid>
      </Grid>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

