import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../img/Argenco_pijl_outline_green_rgb.svg";
// @ts-ignore
import JumboDesktop from "../../img/cut/ArgenCo_Main_Visual_Desktop.jpg";
// @ts-ignore
import JumboTablet from "../../img/cut/ArgenCo_Main_Visual_Tablet.jpg";
// @ts-ignore
import JumboMobile from "../../img/cut/ArgenCo_Main_Visual_Mobile.jpg";

// @ts-ignore
import Explosion1 from "../../img/explosion/brooke-cagle-a1keXHJZAac-unsplash.jpg";
// @ts-ignore
import Explosion2 from "../../img/explosion/christopher-campbell-rDEOVtE7vOs-unsplash.jpg";
// @ts-ignore
import Explosion3 from "../../img/explosion/clint-mckoy-_J2JFRHLijI-unsplash.jpg";
// @ts-ignore
import Explosion4 from "../../img/explosion/esther-ann-glpYh1cWf0o-unsplash.jpg";
// @ts-ignore
import Explosion5 from "../../img/explosion/linkedin-sales-solutions-Kfzfd8ksE10-unsplash.jpg";
// @ts-ignore
import Explosion6 from "../../img/explosion/stefan-stefancik-QXevDflbl8A-unsplash.jpg";

import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack
} from '@mui/material';

import {
  Benefit,
  Cta,
  Footer,
  InputButton,
  NewsItem,
  Section
} from '../../components/v2/custom';
import {
  LightGreenButton,
  Page,
  TransparentButton,
  Typography
} from '../../components/v2/styled';

import { Benefit as Ben } from '../../models/Benefit';
import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Homepage: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [waitlistEmail, setWaitlistEmail] = useState<string>('');

  const {
    data: benefits,
    // isLoading: adviceUploadsLoading,
    // isError: adviceUploadsError,
    isSuccess: benefitsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "benefits"],
    queryFn: () =>
      fetch_all<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'discount_name', 'get_item'],
          per_page: 3,
          order: 'weight_homepage DESC',
          filter: {
            advanced: {
              enabled: 1
            }
          }
        },
        undefined
      ),
    // enabled: false,
  });
  const {
    data: newsItems,
    // isLoading: adviceUploadsLoading,
    // isError: adviceUploadsError,
    isSuccess: newsItemsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "newsitems"],
    queryFn: () =>
      fetch_all<NI>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_news',
          fields: ['id', 'created_at', 'subject', 'get_item', 'teaser', 'tag|id/name'],
          per_page: 3,
          order: 'weight DESC',
          filter: {
            advanced: {
              is_hidden: 0,
              enabled: 1
            }
          }
        },
        undefined
      ),
    // enabled: false,
  });








  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);

    const positionsMobile = [
      { x: "-35vw", y: "-42vh" },
      { x: "0", y: "-35vh" },
      { x: "-30vw", y: "35vh" },
      { x: "48vw", y: "-40vh" },
      { x: "30vw", y: "40vh" },
      { x: "50vw", y: "15vh" }
    ];

    const positionsDesktop = [
      { x: "-25vw", y: "-30vh" },
      { x: "-34vw", y: "0" },
      { x: "-30vw", y: "40vh" },
      { x: "30vw", y: "42vh" },
      { x: "35vw", y: "10vh" },
      { x: "28vw", y: "-20vh" }
    ];

    const getPositions = () =>
      window.innerWidth > 1024 ? positionsDesktop : positionsMobile;

    const createTimeline = () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".scrollable-content",
          start: "top top",
          end: "+=10",
          scrub: 2,
          // @ts-ignore
          anticipatePin: true,
          pin: true
        }
      });

      tl.add("start");

      const positions = getPositions();
      const images = gsap.utils.toArray(".scrollable-content__images__image");
      images.forEach((image, index) => {
        const { x, y } = positions[index] || { x: "0", y: "0" };
        tl.fromTo(
          // @ts-ignore
          image,
          {
            scale: 0.5,
            x: 0,
            y: 0
          },
          {
            scale: 1,
            x: x,
            y: y,
            duration: 5,
            ease: "power1.inOut"
          },
          "start+=" + index * 0.1
        );
      });

      tl.fromTo(
        ".scrollable-content__content",
        {
          scale: 0.1,
          opacity: 0.15
        },
        {
          scale: 1,
          opacity: 1,
          duration: 5,
          ease: "power1.inOut"
        },
        "start"
      );

      return tl;
    };

    let timeline = createTimeline();

    window.addEventListener("resize", () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      timeline.kill();
      timeline = createTimeline();
      ScrollTrigger.refresh();
    });

  }, []);

  return <Page jumbo={<>
    <Grid item xs={12} sx={{padding: '0px !important', position: 'relative', height: {xs: '800px', md: '100vh'}}}>
      <Box sx={{
        display: {xs: 'block', md: 'none', lg: 'none'},
        background: `no-repeat url(${JumboMobile})`,
        backgroundSize: 'cover',
        width: '100vw',
        height: '265px',
        backgroundPositionY: '50%',
        borderRadius: '0px 0px 16px 16px',
        position: 'relative',
        zIndex: 1201
      }} />
      <Box sx={{display: {xs: 'none', md: 'block', lg: 'none'}}}><img src={JumboTablet} style={{minWidth: '100vw', minHeight: '100vh', width: '100vw'}} /></Box>
      <Box sx={{display: {xs: 'none', md: 'none', lg: 'block'}}}><img src={JumboDesktop} style={{minWidth: '100vw', minHeight: '100vh', width: '100vw'}} /></Box>
      <Container sx={{
        position: 'absolute', 
        top: {
          xs: 239,
          md: 0
        }, 
        left: 0, 
        zIndex: 1200, 
        width: '100%', 
        maxWidth: '100% !important', 
        backgroundColor: {xs: theme.palette.secondary.light, md: 'transparent'},
        paddingBottom: {
          xs: 15,
          md: 0
        },
        borderRadius: {
          xs: '0px 0px 16px 16px',
          md: 0
        },
      }}>
        <Container fixed>
          <Typography sx={{
            marginTop: {
              xs: 10,
              md: 24
            },
          }} variant="h1" html fuck_p_tags>{t("nw.jumbo.title")}</Typography>
          <Typography sx={{
            marginTop: {
              xs: 2,
              md: 3
            },
          }} variant="subtitle1" html fuck_p_tags>{t("nw.jumbo.intro")}</Typography>
          <LightGreenButton
            id="member"
            label={t("nw.jumbo.button.become_member")}
            sx={{marginTop: 3}}
            onClick={() => {
              navigate("/buy");
            }}
          />
          <TransparentButton
            id="benefits"
            label={t("nw.jumbo.button.discover_benefits")}
            sx={{
              marginLeft: {
                xs: 0,
                md: 2
              },
              marginTop: 3
            }}
            onClick={() => {
              navigate("/benefits");
            }}
          />
        </Container>
      </Container>
    </Grid>
  </>}>
    <Section sx={{zIndex: 950, "& > div": {position: 'relative'}}}>
      <Box sx={{
        width: {
          sx: '100%',
          md: '80%',
          lg: '60%'
        }
      }}>
        <Typography variant="h2" html fuck_p_tags sx={{color: theme.palette.primary.light}}>{t("nw.home.section.about_argenco.title")}</Typography>
        <Typography variant="body1" html fuck_p_tags sx={{marginTop: 3}}>{t("nw.home.section.about_argenco.text")}</Typography>
        <LightGreenButton
          id="about"
          label={t("nw.home.section.about_argenco.button.cta")}
          sx={{marginTop: 3}}
          onClick={() => {
            navigate("/about/team");
          }}
        />
      </Box>
      <Box sx={{
        position: 'absolute',
        right: 52,
        bottom: 20,
        "& svg": {
          transform: "rotate(45deg) scale(1.7)",
          width: '15vw'
        },
        display: {
          xs: 'none',
          md: 'block'
        }
      }}><Arrow /></Box>
    </Section>
    <Section sx={{zIndex: 949}} dark>
      <Typography variant="h2" html fuck_p_tags>{t("nw.home.section.benefits.title")}</Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={8} sx={{position: 'relative'}}>
          <Stack direction="row" spacing={2} sx={{
            overflowX: 'auto',
            marginTop: 10,
          }}>
            {benefitsSuccess && benefits.map(benefit => <Benefit benefit={benefit} sx={{
              minWidth: {xs: '99%', md: '49%', lg: '31%'},
              maxWidth: {xs: '99%', md: '49%', lg: '31%'}
            }} />)}
          </Stack>
          <Box sx={{
            display: {
              xs: 'block',
              lg: 'none'
            },
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            height: '60%',
            zIndex: 1201,
            background: `linear-gradient(180deg, transparent 0%, ${theme.palette.secondary.light} 60%, ${theme.palette.secondary.light} 100%)`,

          }} />
          <Box sx={{
            display: {
              xs: 'none',
              lg: 'block'
            },
            position: 'absolute',
            top: 0,
            right: 0,
            width: '20%',
            height: '100%',
            zIndex: 1201,
            background: `linear-gradient(90deg, transparent 0%, ${theme.palette.secondary.light} 80%, ${theme.palette.secondary.light} 100%)`,

          }} />
        </Grid>
        <Grid item xs={12} lg={4} sx={{
          paddingTop: {
            xs: 2,
            lg: 10
          },
          paddingLeft: {
            xs: 0,
            lg: 2
          }
        }}>
          <Typography variant="h3" sx={{marginBottom: 2}}>{t("nw.home.section.benefits.subsection.title")}</Typography>
          <Typography variant="body1">{t("nw.home.section.benefits.subsection.text")}</Typography>
          <List sx={{
            paddingLeft: 0,
            listStyle: 'none',
            "li::before": {
              content: '""',
              borderColor: `transparent ${theme.palette.primary.light}`,
              borderStyle: 'solid',
              borderWidth: '0.35em 0 0.35em 0.45em',
              display: 'block',
              height: 0,
              width: 0,
              left: '-1em',
              // top: '0.9em',
              position: 'relative'
            }
          }}>
            <ListItem><Typography variant="body1">{t("nw.home.section.benefits.subsection.li1")}</Typography></ListItem>
            <ListItem><Typography variant="body1">{t("nw.home.section.benefits.subsection.li2")}</Typography></ListItem>
            <ListItem><Typography variant="body1">{t("nw.home.section.benefits.subsection.li3")}</Typography></ListItem>
          </List>
        </Grid>
      </Grid>
    </Section>
    <Section sx={{zIndex: 948}}>
      <div className="scrollable-content">
        <div className="scrollable-content__content">
          <Typography variant="h2" html fuck_p_tags>{t("nw.home.section.explosion.title")}</Typography>
          <Typography sx={{
            marginTop: {
              xs: 2,
              md: 3
            },
          }} variant="body1" html fuck_p_tags>{t("nw.home.section.explosion.text")}</Typography>
          <LightGreenButton
            id="join"
            label={t("nw.home.section.explosion.button.cta")}
            sx={{marginTop: 3}}
            onClick={() => {
              navigate("/buy");
            }}
          />
        </div>
        <div className="scrollable-content__images">
          <img className="scrollable-content__images__image img-01" src={Explosion2} />
          <img className="scrollable-content__images__image img-02" src={Explosion4} />
          <img className="scrollable-content__images__image img-03" src={Explosion3} />
          <img className="scrollable-content__images__image img-04" src={Explosion6} />
          <img className="scrollable-content__images__image img-05" src={Explosion5} />
          <img className="scrollable-content__images__image img-06" src={Explosion1} />
        </div>
      </div>

      <Cta sx={{
        marginTop: 20
      }}>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.home.section.waitlist.title")}</Typography>
        <Typography sx={{
          marginTop: {
            xs: 2,
            md: 3
          },
          textAlign: 'center'
        }} variant="body1" html fuck_p_tags>{t("nw.home.section.waitlist.text")}</Typography>

        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <InputButton
            value={waitlistEmail}
            setValue={(v) => {
              setWaitlistEmail(v);
            }}
            btnLabel={t("nw.home.section.waitlist.cta_subscribe")}
            placeholder={t("nw.home.section.waitlist.cta_placeholder")}
            onClick={() => {
              setWaitlistEmail('');
              // TODO
            }}
          />
        </Box>
      </Cta>
    </Section>
    <Section sx={{zIndex: 947, backgroundColor: 'white', paddingBottom: 0}}>
      <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.home.section.news.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags sx={{textAlign: 'center', marginTop: 3}}>{t("nw.home.section.news.intro")}</Typography>
      <Stack direction="row" spacing={2} sx={{
        overflowX: 'auto',
        marginTop: 10,
        paddingBottom: 2,
        "&::-webkit-scrollbar": {
          height: '4px',
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "8px",
          boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.light
        },
      }}>
        {newsItemsSuccess && newsItems.map(newsItem => <NewsItem newsItem={newsItem} sx={{
          minWidth: {xs: '90%', md: '66%', lg: '32%'},
          maxWidth: {xs: '90%', md: '66%', lg: '32%'}
        }} />)}
      </Stack>
    </Section>
    <Footer />
  </Page>;
}

