import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

// import { loginAtom, renew_sign_in } from '../../lib/auth';
// import { useAtom } from 'jotai';

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_green_rgb.svg";
// @ts-ignore
import {ReactComponent as ArrowMint} from "../../../img/Argenco_pijl_mint_rgb.svg";

import {
  IconButton,
  Pagination as Pag,
  Stack,
} from '@mui/material';

// const { DateTime } = require("luxon");

type Props = {
  count: number;
  page: number;
  setPage: (n:number) => void;
};

export const Pagination: FunctionComponent<Props> = ({count, page, setPage}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();

  return (<Stack spacing={2} alignItems="center" direction="row">
    <IconButton sx={{
      "& svg": {
        transform: "rotate(270deg)",
        width: '20px',
        marginTop: -1,
        marginLeft: -0.25
      },
      display: 'inline-block',
      marginRight: 'auto',
      height: '32px',
      width: '32px',
      paddingTop: '5px',
      backgroundColor: theme.palette.secondary.light,
      "&.Mui-disabled": {
        border: '1px solid transparent',
        borderColor: theme.palette.secondary.light,
      }
    }} disabled={page <= 0} onClick={() => {
      setPage(page - 1);
    }}>
      {page > 0 && <Arrow />}
      {page <= 0 && <ArrowMint />}
    </IconButton>
    <Pag
      count={Math.ceil(count/9)}
      page={page + 1}
      onChange={(e, v) => {setPage(v - 1);}}
      boundaryCount={2}
      siblingCount={2}
      hideNextButton
      hidePrevButton
      sx={{
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        "li > button": {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.dark
        },
        "li > button.Mui-selected": {
          backgroundColor: theme.palette.primary.light,
        }
      }}
    />
    <IconButton sx={{
      "& svg": {
        transform: "rotate(90deg)",
        width: '20px',
        marginTop: -1,
        marginLeft: -0.25
      },
      display: 'inline-block',
      marginleft: 'auto',
      height: '32px',
      width: '32px',
      paddingTop: '5px',
      backgroundColor: theme.palette.secondary.light,
      "&.Mui-disabled": {
        border: '1px solid transparent',
        borderColor: theme.palette.secondary.light,
      }
    }} disabled={page >= (Math.ceil(count/9) - 1) } onClick={() => {
      setPage(page + 1);
    }}>
    {page < (Math.ceil(count/9) - 1) && <Arrow />}
    {page >= (Math.ceil(count/9) - 1) && <ArrowMint />}
    </IconButton>
  </Stack>);
}
