import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../lib/v31lib";
import { loginAtom, do_login_otp } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
import {
  Cta,
  InputButton,
  Section,
  WhiteSection
} from '../../components/v2/custom';
import {
  Page,
  Typography
} from '../../components/v2/styled';
import { EmissionChoiceMe } from './emission/ChoiceMe';
import { EmissionChoiceMeExisting } from './emission/ChoiceMeExisting';
import { EmissionChoiceMeNewItsme } from './emission/ChoiceMeNewItsme';
import { EmissionChoiceMeNewNew } from './emission/ChoiceMeNewNew';
import { EmissionChoiceMeNew } from './emission/ChoiceMeNew';
import { EmissionChoiceMinor } from './emission/ChoiceMinor';
import { EmissionForgotLink } from './emission/ForgotLink';
import { EmissionLanding } from './emission/Landing';
import { EmissionLoader } from './emission/Loader';
import { EmissionError } from './emission/Error';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Emission: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const [step, setStep] = useState<number>(!!state?.step ? state.step : -1);
  const [sub, setSub] = useState<string>(!!state?.sub ? state.sub : '');
  const [user, setUser] = useState<User>({
    id: undefined,
    auth_token: '',
    email: ''
  });
  const [waitlistEmail, setWaitlistEmail] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [forgotMessage, setForgotMessage] = useState<string>('');
  const [forgotSuccess, setForgotSuccess] = useState<boolean>(false);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [vennr, setVennr] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [showOtp, setShowOtp] = useState<boolean>(false);

  // STEP
  // -1 => loader
  // 0 => landing
  // 5 => for me
  // 6 => existing
  // 7 => new
  // 8 => new/itsme
  // 9 => new/new
  // 15 => for minor
  // 50 => stocks
  // 100 => forgot link
  // 404 => error
  // 999 => waitlist
  




  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      step: number;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);

      if (variables.step === -1) {
        // initial fetch
        if (!!data.custom_result.enabled) {
          setStep(0);
        } else {
          setStep(999);
        }
      } else {
        if (variables.step === 6) {
          if (!!data.custom_result.mfa_status_0_verification) {
            setShowOtp(true);
          } else {
            if (!!data.custom_result.stocks_url) {
              navigate(data.custom_result.stocks_url);
            } else {
              setStep(404);
            }
          }
        } else {
          if (variables.step === 8) {

          } else {
            if (variables.step === 100) {
              // forgot
              setForgotSuccess(true);
              setForgotMessage(data.custom_result.message);
            }
          }
        }
      }
      
      if (!!data.custom_result.user) {
        setUser(data.custom_result.user);
      }
    },
  });




  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    mutationCustomAction.mutate({
      formData: {
        object: 'emission',
        class_action: 'custom_api_get_info',
        testing: 1,
        sub: sub,
        user_id: user.id
      },
      step: step
    });
  }, []);

  return <Page>
    {step !== 999 && <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5} sx={{
        marginTop: {
          xs: 2,
          lg: 4
        }
      }}>
        {step === -1 && <EmissionLoader />}
        {step === 0 && <EmissionLanding
          setStep={setStep}
        />}
        {step === 5 && <EmissionChoiceMe
          setStep={setStep}
        />}
        {step === 6 && <EmissionChoiceMeExisting
          setStep={setStep}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          vennr={vennr}
          setVennr={setVennr}
          birthdate={birthdate}
          setBirthdate={setBirthdate}
          otp={otp}
          setOtp={setOtp}
          showOtp={showOtp}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_validate',
                username: username,
                password: password,
                birthdate: birthdate,
                vennr: vennr,
                otp: otp
              },
              step: 6
            });
          }}
        />}
        {step === 7 && <EmissionChoiceMeNew
          setStep={setStep}
        />}
        {step === 8 && <EmissionChoiceMeNewItsme
          setStep={setStep}
        />}
        {step === 9 && <EmissionChoiceMeNewNew
          setStep={setStep}
        />}
        {step === 15 && <EmissionChoiceMinor
          setStep={setStep}
        />}
        {step === 100 && <EmissionForgotLink
          email={email}
          setEmail={setEmail}
          success={forgotSuccess}
          message={forgotMessage}
          setStep={setStep}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'emission',
                class_action: 'custom_api_get_info',
                testing: 1,
                mode: 'forgot',
                email: email
              },
              step: 100
            });
          }}
        />}
        {step == 404 && <EmissionError
          onBack={() => {
            setStep(5);
          }}
        />}
      </Grid>
    </WhiteSection>}

    {step === 999 && <Section normalPage sx={{
      marginTop: {
        xs: 2,
        lg: 4
      }
    }}>
      <Cta>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.home.section.waitlist.title")}</Typography>
        <Typography sx={{
          marginTop: {
            xs: 2,
            md: 3
          },
          textAlign: 'center'
        }} variant="body1" html fuck_p_tags>{t("nw.home.section.waitlist.text")}</Typography>

        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <InputButton
            value={waitlistEmail}
            setValue={(v) => {
              setWaitlistEmail(v);
            }}
            btnLabel={t("nw.home.section.waitlist.cta_subscribe")}
            placeholder={t("nw.home.section.waitlist.cta_placeholder")}
            onClick={() => {
              setWaitlistEmail('');
              // TODO
            }}
          />
        </Box>
      </Cta>
    </Section>}
  </Page>;
}

