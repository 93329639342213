import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  TransparentButton,
  Dt,
  Typography
} from '../styled';

import { NewsItem as NI } from '../../../models/NewsItem';


type Props = {
  newsItem: NI;
  sx?: any;
};

export const NewsItem: FunctionComponent<Props> = ({newsItem, sx}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [ctrlActive, setCtrlActive] = useState(false);
  const [stopped, setStopped] = useState(true);
  let path = `/news/${newsItem.id}`;

  return (<>
    <Box
      sx={{
        position: 'relative',
        zIndex: 1200,
        ...sx
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            if (!!path) navigate(path);
          }
          window.scrollTo(0, 0);
          setCtrlActive(false);
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
    >
      {!!(newsItem?.tag_name || {})[i18n.resolvedLanguage || 'nl'] && <Box sx={{
        padding: '8px 12px',
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 1204,
        borderRadius: '8px'
      }}>
        <Typography variant="body2" sx={{
          textTransform: 'uppercase'
        }}>{(newsItem?.tag_name || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Box>}

      <Box sx={{
        // borderRadius: '10px',
        overflow: 'hidden',
        width: '100%',
        height: '200px',
        background: `no-repeat url(${newsItem.get_item})`,
        backgroundSize: 'cover',

        "--r": "10px", /* the radius */
        "--s": "38px", /* the size of the corner*/
        // width: 250px;
        aspectRatio: 1,
        // background: #3FB8AF;
        borderRadius: "var(--r)",
        "--_m":"/calc(2*var(--r)) calc(2*var(--r)) radial-gradient(#000 70%,#0000 72%) no-repeat",
        mask: "right 0 bottom calc(var(--s) + var(--r)) var(--_m), right calc(var(--s) + var(--r)) bottom 0 var(--_m), radial-gradient(var(--s) at 100% 100%,#0000 99%,#000 101%) calc(-1*var(--r)) calc(-1*var(--r)) no-repeat, conic-gradient(from 90deg at calc(100% - var(--s) - 2*var(--r)) calc(100% - var(--s) - 2*var(--r)), #0000 25%,#000 0)"
      }} />

      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 0,
          top: 166,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      <Typography variant="body2" sx={{
        textTransform: 'uppercase',
        color: '#B2B2B2',
        marginTop: 1
      }}><Dt d={newsItem.created_at} f="d LLLL y" /></Typography>

      <Typography variant="h3" sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        height: '72px',
        textTransform: 'none'
      }}>{(newsItem.subject || {})[i18n.resolvedLanguage || 'nl']}</Typography>

      <Typography variant="body1" sx={{
        marginTop: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        height: '51px'
      }}>{(newsItem.teaser || {})[i18n.resolvedLanguage || 'nl']}</Typography>

      <TransparentButton
        id="read_more"
        label={t("nw.cta.read_more")}
        sx={{
          marginTop: 4
        }}
        path={`/news/${newsItem.id}`}
      />
    </Box>
  </>);
}