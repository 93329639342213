import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import ImgSustain from "../../../img/cut/ArgenCo_Duurzaamheid_1024px.jpg";

import {
  Box,
  Grid,
  List,
  ListItem,
} from '@mui/material';

import {
  Footer,
  Hero,
  Investment,
  WhiteSection
} from '../../../components/v2/custom';
import {
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
import { NewsItem as NI } from '../../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutSustainability: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  const liArrow = () => {
    return <Box sx={{
      "& svg": {
        transform: "rotate(90deg)",
        width: '24px',
        marginBottom: -1
      },
      marginRight: 2.5
    }}><Arrow /></Box>
  }
  const numCircle = (num:number) => {
    return <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '40px',
        padding: 1,
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'white',
        marginRight: 2.5
      }}
    >
      {num}
    </Box>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      children={<>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.sustainability.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.sustainability.hero.subsection")}</Typography>
      </>}
    />

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" sx={{
            marginTop: 8,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            },
            color: theme.palette.primary.light
          }}>{t("nw.about.sustainability.par1.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="body1" html fuck_p_tags altLinks sx={{
            display: 'inline-block',
            textAlign: 'left',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.sustainability.par1.subsection")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Box sx={{
            display: 'inline-block',
            textAlign: 'left',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            },
            padding: 4,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '10px'
          }}>
            <Typography variant="body2" html fuck_p_tags>{t("nw.about.sustainability.par1.grey.top")}</Typography>
            <List sx={{
              paddingLeft: 0,
              listStyle: 'none',
              "& li": {
                paddingLeft: 0
              }
            }} component="ul">
              <ListItem>{liArrow()} <Typography variant="body1">{t("nw.about.sustainability.par1.grey.li1")}</Typography></ListItem>
              <ListItem>{liArrow()} <Typography variant="body1">{t("nw.about.sustainability.par1.grey.li2")}</Typography></ListItem>
              <ListItem>{liArrow()} <Typography variant="body1">{t("nw.about.sustainability.par1.grey.li3")}</Typography></ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Box sx={{
        width: '100%',
        borderRadius: '10px',
        height: '500px',
        backgroundImage: `url(${ImgSustain})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        marginTop: 8,
      }} />
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" sx={{
            marginTop: 8,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            },
            color: theme.palette.primary.light
          }}>{t("nw.about.sustainability.par2.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="body1" html fuck_p_tags altLinks sx={{
            display: 'inline-block',
            textAlign: 'left',
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.sustainability.par2.subsection")}</Typography>
        </Grid>
      </Grid>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

