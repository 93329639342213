import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Typography as Typo
} from '@mui/material';

var he = require('he');

type TypographyProps = {
  sx?: object;
  onClick?: (e:unknown) => void;
  children: React.ReactNode | string;
  html?: boolean;
  noWrap?: boolean;
  fuck_p_tags?: boolean;
  altLinks?: boolean;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined;
};

export const Typography: FunctionComponent<TypographyProps> = ({fuck_p_tags, variant, sx, children, onClick, html, noWrap, altLinks}) => {
  const theme = useTheme();

  return (!!html && typeof(children) === "string" ? <Typo
    variant={variant}
    noWrap={noWrap}
    sx={{
      "a": {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      "a:hover": {
        textDecoration: 'underline'
      },
      "& > p": (!!fuck_p_tags ? {
        marginTop: 0,
        marginBottom: 0
      } : {}),
      ...(!!altLinks ? {
        "a": {
          color: theme.palette.primary.main,
          textDecoration: 'underline'
        },
        "a:hover": {
          fontWeight: 'bold'
        },
      } : {}),
      ...sx
    }}
    onClick={(e) => {if (!!onClick) onClick(e);}}
    dangerouslySetInnerHTML={{ __html: he.decode(children) }}
  ></Typo> : <Typo
    noWrap={noWrap}
    variant={variant}
    sx={{
      "a": {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      "a:hover": {
        textDecoration: 'underline'
      },
      ...sx
    }}
    onClick={(e) => {if (!!onClick) onClick(e);}}
  >
    {typeof(children) === "string" ? he.decode(he.decode(children.replace(/<[^>]*>?/gm, ''))) : children}
  </Typo>);
}