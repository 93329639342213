import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  Select as Sel
} from '@mui/material';

import {
  Typography
} from '../styled';

var he = require('he');

type Props = {
  fullWidth?: boolean;
  displayEmpty?: boolean;
  backend?: boolean;
  noCaption?: boolean;
  starred?: boolean;
  value: number | string;
  caption?: string;
  placeholder?: string;
  error?: string;
  helperText?: string;
  setValue: (s:string|number) => void;
  sx?: any;
  id: string;
  children?: any;
  icon?: ReactElement;
};

export const Select: FunctionComponent<Props> = ({starred, icon, id, children, sx, fullWidth, displayEmpty, value, setValue, backend, noCaption, caption, placeholder, error, helperText}) => {
  const theme = useTheme();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    <label>
      {!!backend && !noCaption && (<Typography variant="caption" sx={{
        marginTop: 1,
        marginRight: 1,
      }}>{he.decode(((!!caption ? caption : placeholder) || '').replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}
      {!backend && caption && (<Typography variant="caption" sx={{opacity: 0.5}}>{he.decode((caption).replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}
      <Sel
        fullWidth={fullWidth}
        displayEmpty={displayEmpty}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        id={id}
        sx={{
          "> svg": {
            zIndex: 1000
          },
          backgroundColor: "white",
          width: "100%",
          borderRadius: 10,
          marginTop: 0,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...sx
        }}
        endAdornment={ !!icon ? <InputAdornment position="end">{icon}</InputAdornment> : null}
        children={children}
      />
    </label>
  </>);
}