import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
// import enNs1 from './locales/en.json';
// import nlNs1 from './locales/nl.json';
// import frNs1 from './locales/fr.json';

export const defaultNS = 'translations';
export const fallbackNS = 'translations';

if (!i18next.isInitialized) {
  i18next.use(LanguageDetector).use(initReactI18next).use(XHR).init({
    debug: false,
    fallbackLng: 'nl',
    supportedLngs: ['nl', 'fr'],
    lowerCaseLng: true,
    defaultNS: 'translations',
    ns: 'translations',
    keySeparator: false,
    nsSeparator: false,
    nonExplicitSupportedLngs: true,
    detection: {
      lookupQuerystring: 'locale'
    },
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    },
    fallbackNS,
    backend: {
      loadPath: 'https://api.argenco.be/locales/{{lng}}/{{ns}}.json',
      crossDomain: true
    }
  });
}

export default i18next;