export const LOGIN_URL = '/v3/users/login';
export const LOGOUT_URL = '/v3/users/logout';
export const RENEW_URL = '/v3/users/renew_sign_in';
//
export const loginFields = [
  'id', 'first_name', 'last_name', 'auth_token', 'email', 'extra_data', 'type', 'mfa_status', 'vennoot_number'
];

export type User = {
  id?: number;
  auth_token: string;
  created_at?: string;
  email: string;
  repeat_email?: string;
  first_name?: string;
  last_name?: string;
  vennoot_number?: string;
  birth_date?: string;
  phone?: string;
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  zicht_account?: string;
}
