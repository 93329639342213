import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Grid,
} from '@mui/material';

import {
  Footer,
  Hero,
  Investment,
  WhiteSection
} from '../../../components/v2/custom';
import {
  Benefit as SkelBenefit,
} from '../../../components/v2/skeleton';
import {
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
import { NewsItem as NI } from '../../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutInvestments: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);


  const {
    data: investments,
    isLoading: investmentsLoading,
    // isError: adviceUploadsError,
    isSuccess: investmentsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "investments"],
    queryFn: () =>
      fetch_all<NI>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_news',
          fields: ['id', 'created_at', 'subject', 'get_item'],
          // id', 'field_title', 'field_body', 'image_url', 'created_at', 'tag|field_name', 'weight
          per_page: 1000,
          order: 'weight DESC, created_at DESC',
          filter: {
            advanced: {
              enabled: 1,
              investment: 1
            }
          }
        },
        undefined
      ),
    // enabled: false,
  });







  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      children={<>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.investments.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.investments.hero.subsection")}</Typography>
      </>}
    />

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        {!!investmentsLoading && [0, 1, 2, 3, 4, 5].map(i => <Grid item xs={12} md={6} lg={4}>
          <SkelBenefit />
        </Grid>)}
        {!investmentsLoading && investmentsSuccess && investments.map(investment => <Grid item xs={12} md={6} lg={4}>
          <Investment newsItem={investment} sx={{
            minWidth: '99%',
            maxWidth: '99%'
          }} />
        </Grid>)}
      </Grid>
    </WhiteSection>
    
    <Footer normalPage />
  </Page>;
}

