import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

type Props = {
  sx?: object;
  children?: any;
  picLeft?: any;
  picRight?: any;
};

export const Hero: FunctionComponent<Props> = ({sx, picLeft, picRight, children}) => {
  const theme = useTheme();

  return (<Box sx={{
    width: "calc(100% + 48px)",
    marginLeft: -3,
    marginRight: -3,
    padding: 0,
    // padding: {
    //   xs: 1,
    //   md: 3,
    //   lg: 3
    // },
    paddingTop: {
      xs: 1,
      md: 2,
      lg: 4
    },
    paddingBottom: 1.25,
    // borderRadius: '0px 0px 16px 16px',
    position: 'relative',
    backgroundColor: 'transparent',
    zIndex: 999,
    ...sx
  }}>
    <Container fixed>
      <Grid container spacing={0}>
        {!!picLeft && <Grid item xs={12} lg={5} sx={{
          paddingRight: 2,
          display: {
            xs: 'none',
            lg: 'block'
          },
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            background: `url(${picLeft})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }} />
        </Grid>}
        <Grid item xs={12} lg={(!!picLeft || !!picRight) ? 7 : 12}>
          <Box sx={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            padding: {
              xs: 2.5,
              md: 5,
              lg: 14
            },
            paddingTop: {
              xs: 5,
              md: 10,
              lg: 13
            },
            paddingBottom: {
              xs: 5,
              md: 10,
              lg: 13
            },
            backgroundColor: theme.palette.primary.light,
          }}>
            {children}
          </Box>
        </Grid>
        {!!picRight && <Grid item xs={12} lg={5} sx={{
          paddingLeft: {
            xs: 0,
            lg: 2
          },
          marginTop: {
            xs: 2.5,
            lg: 0
          }
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: '400px',
            borderRadius: '16px',
            background: `url(${picRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }} />
        </Grid>}
        {!!picLeft && <Grid item xs={12} sx={{
          display: {
            xs: 'block',
            lg: 'none'
          },
          marginTop: 2.5
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: '400px',
            borderRadius: '16px',
            background: `url(${picLeft})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }} />
        </Grid>}
      </Grid>
    </Container>
  </Box>);
}