import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
// import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';


// import {
//   Box,
//   Grid,
//   MenuItem,
// } from '@mui/material';

import {
  Typography
} from '../../components/v2/styled';
import { MenuDesktop } from './menu/Desktop';
import { MenuMobile } from './menu/Mobile';
import { MenuTablet } from './menu/Tablet';

// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  drawerOpen?: boolean;
  setDrawerOpen?: (b:boolean) => void;
}

export const SharedHeader: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [scrolled, setScrolled] = useState<boolean>(false);

  // const {
  //   data: usr,
  //   // isLoading: adviceUploadsLoading,
  //   // isError: adviceUploadsError,
  //   // isSuccess: adviceUploadsSuccess,
  //   // refetch: adviceUploadsRefetch,
  // } = useQuery({
  //   queryKey: ["full_user", login?.id],
  //   queryFn: () =>
  //     fetch_one<User>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'user',
  //         fields: [...loginFields, 'last_diary_entry'],
  //         id: login?.id,
  //         ob: {
  //           id: login?.id
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!login,
  // });

  







  const LanguagePicker = (b:boolean, white?:boolean) => {
    return (<>
      <Typography sx={{
        cursor: 'pointer',
        fontSize: '14px',
        display: 'inline-block',
        textDecoration: (i18n.resolvedLanguage !== 'nl' ? 'underline' : undefined),
        color: theme.palette.primary.main,
        ...(!!white ? {
          color: 'white'
        } : {})
      }} onClick={() => {
        i18n.changeLanguage('nl');
      }}>Nederlands</Typography> - <Typography sx={{
        cursor: 'pointer',
        fontSize: '14px',
        display: 'inline-block',
        textDecoration: (i18n.resolvedLanguage !== 'fr' ? 'underline' : undefined),
        // color: ((scrolled || b) ? theme.palette.primary.main : 'white'),
        color: theme.palette.primary.main,
        ...(!!white ? {
          color: 'white'
        } : {})
      }} onClick={() => {
        i18n.changeLanguage('fr');
      }}>Français</Typography>
    </>);
  }

  useEffect(() => {
    const scrollFun = () => {
      // @ts-ignore
      if (window.scrollY < 1) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    }
    
    // @ts-ignore
    window.addEventListener("scroll", scrollFun);
  
    return () => {
      // @ts-ignore
      window.removeEventListener("scroll", scrollFun);
    };
  }, []);

  return <>
    <MenuDesktop
      LanguagePicker={LanguagePicker}
      scrolled={scrolled}
    />
    <MenuTablet
      LanguagePicker={LanguagePicker}
      scrolled={scrolled}
    />
    <MenuMobile
      LanguagePicker={LanguagePicker}
      scrolled={scrolled}
    />
  </>;
}

