import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  Grid,
} from '@mui/material';
import {
  InputButton
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  TextButton,
  Typography
} from '../../../components/v2/styled';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  email: string;
  setEmail: (s:string) => void;
  onSubmit: () => void;
  success: boolean;
  message: string;
  setStep: (n:number) => void;
}

export const EmissionForgotLink: FunctionComponent<Props> = ({email, setEmail, onSubmit, success, message, setStep}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h1" sx={{
        marginTop: 4,
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.emission.forgot.title")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        maxWidth: {
          xs: '100%',
          lg: '440px'
        }
      }}>{t("nw.emission.forgot.subsection")}</Typography>
    </Grid>
    {!success && <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <InputButton
        value={email}
        setValue={(v) => {
          setEmail(v);
        }}
        btnLabel={t("nw.emission.forgot.cta_submit")}
        placeholder={t("nw.emission.forgot.email")}
        onClick={() => {
          onSubmit();
        }}
        sx={{
          width: {
            xs: '100%',
            md: '300px'
          },
          marginTop: -0.25
        }}
      />
    </Grid>}

    {!!success && message === 'success' && <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <ErrorBox
        type="success"
        text={t("nw.emission.forgot.message_success")}
      />
    </Grid>}
    {!!success && message !== 'success' && <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <ErrorBox
        type="error"
        text={t(`nw.emission.forgot.message_${message}`)}
      />
    </Grid>}
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <TextButton
        id="back_btn"
        label={t("nw.emission.forgot.cta_back")}
        onClick={() => {
          setStep(0);
          window.scrollTo(0, 0);
        }}
      />
    </Grid>
  </>;
}

