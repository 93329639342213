import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation } from '@tanstack/react-query';

import { fetch_one } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  Cta,
  Footer,
  Section,
  WhiteSection
} from '../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  Select,
  TextInput,
  Typography
} from '../../components/v2/styled';

// import { Faq as FaqItem } from '../../models/Faq';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

var he = require('he');
// const { DateTime } = require("luxon");

type Props = {}

export const Contact: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [formDone, setFormDone] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setFormDone(true);
      setFormSuccess(data.custom_result.success);

      if (data.custom_result.success) {
        setFirstName('');
        setLastName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }
    },
  });

  const sendForm = () => {
    setFormDone(false);
    setFormSuccess(false);

    mutationCustomAction.mutate({
      formData: {
        object: 'contact',
        class_action: 'custom_api_send_contact',
        first_name: firstName,
        last_name: lastName,
        email: email,
        reason: subject,
        message: message
      }
    });
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Section normalPage>
      <Cta>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.contact.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.contact.hero.subsection")}</Typography>
        <Box sx={{
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          <DarkGreenButton
            id="goto_faq"
            label={t("nw.contact.hero.cta")}
            onClick={() => {
              navigate('/faq');
            }}
          />
        </Box>
      </Cta>
    </Section>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5} sx={{
        width: {
          xs: '100%',
          lg: '670px'
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 8,
        marginBottom: 8
      }}>
        <Grid item xs={12} md={6} lg={6}>
          <TextInput
            value={firstName}
            onChange={(v) => {
              setFirstName(v);
            }}
            starred
            caption={t("nw.contact.form.first_name")}
            // placeholder={t("nw.contact.form.placeholder.first_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextInput
            value={lastName}
            onChange={(v) => {
              setLastName(v);
            }}
            starred
            caption={t("nw.contact.form.last_name")}
            // placeholder={t("nw.contact.form.placeholder.last_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            value={email}
            onChange={(v) => {
              setEmail(v);
            }}
            starred
            type="email"
            caption={t("nw.contact.form.email")}
            // placeholder={t("nw.contact.form.placeholder.email")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            id="subjects"
            caption={t("nw.contact.form.subject")}
            fullWidth
            value={subject}
            setValue={(v) => {
              setSubject(v.toString());
            }}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              height: '40px',
              paddingTop: 0.25,
              paddingBottom: 0.25,
              backgroundColor: 'transparent',
              "&:hover fieldset": {
                borderColor: 'transparent !important'
              }
            }}
          >
            <MenuItem value="exit">{he.decode((t("nw.contact.form.subjects.exit")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
            <MenuItem value="transfer">{he.decode((t("nw.contact.form.subjects.transfer")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
            <MenuItem value="fiscal">{he.decode((t("nw.contact.form.subjects.fiscal")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
            <MenuItem value="emission">{he.decode((t("nw.contact.form.subjects.emission")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
            <MenuItem value="login">{he.decode((t("nw.contact.form.subjects.login")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
            <MenuItem value="other">{he.decode((t("nw.contact.form.subjects.other")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
          </Select>
        </Grid>
        {false && <Grid item xs={12}>
          faq items
        </Grid>}
        <Grid item xs={12}>
          <TextInput
            value={message}
            onChange={(v) => { 
              setMessage(v);
            }}
            starred
            multiline
            caption={t("nw.contact.form.message")}
            // placeholder={t("nw.contact.form.placeholder.last_name")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'right'}}>
          <DarkGreenButton
            id="submit_btn"
            label={t("nw.contact.form.submit")}
            disabled={!firstName || !lastName || !email || !subject || !message}
            onClick={() => {
              sendForm();
            }}
            sx={{
              "&.Mui-disabled": {
                color: 'white !important'
              }
            }}
          />
          {!!formDone && !!formSuccess && <Typography variant="body1" sx={{color: theme.palette.primary.light}}>{t("nw.contact.form.success")}</Typography>}
          {!!formDone && !formSuccess && <Typography variant="body1" sx={{color: theme.palette.secondary.dark}}>{t("nw.contact.form.fail")}</Typography>}
        </Grid>
      </Grid>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

