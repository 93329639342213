import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
import do_login, { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
import {
  WhiteSection
} from '../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  TextButton,
  TextInput,
  Typography
} from '../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Profile: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  






  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
     profile stuff
  </Page>;
}

