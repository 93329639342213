import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// import { loginAtom, renew_sign_in } from '../../lib/auth';
// import { useAtom } from 'jotai';

import {
  Box
} from '@mui/material';
import {
  WhiteSection
} from '../custom';
import {
  Typography
} from '../styled';

// const { DateTime } = require("luxon");

type Props = {
  blockInfo: {
    type: string;
    id: number;
    get_item_nl: string;
    get_item_fr: string;
    sep_color: string;
    string_value: {
      [z:string]: string;
    };
    title_value: {
      [z:string]: string;
    };
    string_value_right: {
      [z:string]: string;
    };
    title_value_right: {
      [z:string]: string;
    };
    settings: {
      mtop: string;
      mbot: string;
      border: string;
      image_url: {
        [z:string]: string;
      };
    };
  }[];
};

export const BlockInfo: FunctionComponent<Props> = ({blockInfo}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (<>
    {blockInfo.map(block => <Box sx={{
      maxWidth: {
        xs: '100%',
        lg: '670px'
      },
      marginLeft: 'auto',
      marginRight: 'auto'
    }}>
      {block.type === 'image_left' && <WhiteSection dense isTransparent noMinHeight picLeft={i18n.resolvedLanguage === 'fr' ? block.get_item_fr : block.get_item_nl}>
        <Typography variant="h3" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{block.title_value[i18n.resolvedLanguage || 'nl']}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks>{block.string_value[i18n.resolvedLanguage || 'nl']}</Typography>
      </WhiteSection>}
      {block.type === 'image_right' && <WhiteSection dense isTransparent noMinHeight picRight={i18n.resolvedLanguage === 'fr' ? block.get_item_fr : block.get_item_nl}>
        <Typography variant="h3" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{block.title_value[i18n.resolvedLanguage || 'nl']}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks>{block.string_value[i18n.resolvedLanguage || 'nl']}</Typography>
      </WhiteSection>}
      {block.type === 'text' && <WhiteSection dense isTransparent noMinHeight>
        <Typography variant="body1" html fuck_p_tags altLinks>{block.string_value[i18n.resolvedLanguage || 'nl']}</Typography>
      </WhiteSection>}
      {block.type === 'title_text' && <WhiteSection dense isTransparent noMinHeight>
        <Typography variant="h3" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{block.title_value[i18n.resolvedLanguage || 'nl']}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks>{block.string_value[i18n.resolvedLanguage || 'nl']}</Typography>
      </WhiteSection>}
    </Box>)}
  </>);
}
