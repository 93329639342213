import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
} from '@mui/material';

import {
  Typography
} from '../styled';

type Props = {
  children: React.ReactNode | string;
  sx?: any;
  crumbs?: string[];
  title?: string;
  actions?: any;
  titleCrumbs?: {
    title: string;
    path?: string;
    noPath?: boolean;
    state?: any;
  }[],
  jumbo?: React.ReactNode;
};

export const Page: FunctionComponent<Props> = ({children, sx, crumbs, title, actions, titleCrumbs, jumbo}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    <Box sx={{
      // flex: 1,
      // display: "flex",
      position: "relative",
      // maxHeight: '100vh',
      padding: 3,
      paddingTop: !jumbo ? {
        xs: '90px',
        md: '90px'
      } : undefined,
      ...sx
    }}>
      <Box sx={{
        width: '0px',
        height: '0px',
        marginTop: -3,
        marginBottom: 3
      }} id="scroll_anchor" />

      {!!jumbo && <Box sx={{
        width: "calc(100% + 48px)",
        marginLeft: -3,
        marginTop: -6,
        marginRight: -3,
        maxHeight: '100vh',
        borderRadius: '0px 0px 16px 16px',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 999
      }}>
        {jumbo}
      </Box>}

      {!!title && <>
        <Breadcrumbs>
          {titleCrumbs?.map(crumb => crumb.noPath ? <Typography>{crumb.title}</Typography> : (!!crumb.state ? <Typography onClick={() => {navigate(crumb.path || '/', {state: crumb.state});}} sx={{textDecoration: 'underline', color: theme.palette.primary.main, cursor: 'pointer'}}>{crumb.title}</Typography> : <Link href={crumb.path} underline="always">{crumb.title}</Link>))}
          <Typography variant="h1" sx={{color: 'rgba(0, 0, 0, 0.87)'}}>{title}</Typography>
        </Breadcrumbs>
        <Divider sx={{marginBottom: 2, marginTop: 2}}></Divider>
        {!!actions && <>{actions}</>}
      </>}

      {children}
    </Box>
  </>);
}