import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  TransparentButton,
  Dt,
  Typography
} from '../styled';

import { NewsItem as NI } from '../../../models/NewsItem';


type Props = {
  date: string;
  name: string;
  lines: string;
  url: string;
  sx?: any;
};

export const DocumentTile: FunctionComponent<Props> = ({date, name, lines, url, sx}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  // const navigate = useNavigate();

  const [stopped, setStopped] = useState(true);

  return (<>
    <Box
      sx={{
        width: '100%',
        borderRadius: '10px',
        backgroundColor: 'white',
        padding: 4,
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1200,
        ...sx
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!url) {
          window.open(url, "_BLANK");
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!url) window.open(url, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!url) window.open(url, "_BLANK");
        }
      }}
    >
      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 32,
          bottom: 32,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      <Typography variant="body2">{date}</Typography>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="body1" sx={{marginTop: 4}}>{lines}</Typography>
      <TransparentButton
        id="pdf"
        label="PDF"
        sx={{
          backgroundColor: theme.palette.primary.light,
          borderColor: 'transparent',
          "&:hover": {
            backgroundColor: theme.palette.primary.light
          },
          position: 'absolute',
          top: 32,
          right: 32
        }}
      />
      <TransparentButton
        id="download"
        label={t("nw.cta.download")}
        sx={{
          marginTop: 4,
          backgroundColor: theme.palette.secondary.light,
          borderColor: 'transparent',
          "&:hover": {
            backgroundColor: theme.palette.secondary.light
          }
        }}
      />
    </Box>
  </>);
}