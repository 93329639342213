import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import {ReactComponent as ArrowDark} from "../../../img/Argenco_pijl_green_rgb.svg";

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  Typography
} from '../styled';

import { Benefit as Ben } from '../../../models/Benefit';


type Props = {
  benefit: Ben;
  sx?: any;
};

export const Benefit: FunctionComponent<Props> = ({benefit, sx}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [ctrlActive, setCtrlActive] = useState(false);
  let path = `/benefit/${benefit.id}`;

  return (<>
    <Box
      sx={{
        position: 'relative',
        zIndex: 1200,
        cursor: 'pointer',
        ...sx
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            if (!!path) navigate(path);
          }
          window.scrollTo(0, 0);
          setCtrlActive(false);
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
    >
      {!!(benefit?.nw_discount_tag || {})[i18n.resolvedLanguage || 'nl'] && <Box sx={{
        padding: '8px 12px',
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 1204,
        borderRadius: '8px'
      }}>
        <Typography variant="body2" sx={{
          textTransform: 'uppercase'
        }}>{(benefit?.nw_discount_tag || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Box>}

      {!!(benefit?.nw_timing || {})[i18n.resolvedLanguage || 'nl'] && <Box sx={{
        padding: '16px',
        position: 'absolute',
        top: 47,
        left: 0,
        zIndex: 1204,
        borderRadius: '8px',
        height: 193,
        width: 193,
        overflow: 'hidden'
      }}>
        <Box sx={{
          position: 'absolute',
          bottom: -275,
          left: -212,
          "& svg": {
            width: '400px'
          },
          zIndex: 1203,
          transform: "rotate(90deg)"
        }}><Arrow /></Box>
        <Box sx={{
          position: 'absolute',
          top: 70,
          left: 8,
          "& svg": {
            width: '34px',
            height: '34px'
          },
          zIndex: 1205,
          transform: "rotate(90deg)"
        }}><ArrowDark /></Box>
        <Typography variant="body1" sx={{
          textTransform: 'uppercase',
          marginTop: '112px',
          width: '75%',
          position: 'relative',
          zIndex: 1205,
        }}>{(benefit?.nw_timing || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Box>}

      <Box sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        width: '100%',
        height: '240px',
        background: `no-repeat url(${benefit.get_item})`,
        backgroundSize: 'cover',
      }} />

      <Typography variant="h3" sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        height: '72px',
        textTransform: 'none',
        marginTop: 2
      }}>{(benefit.discount_name || {})[i18n.resolvedLanguage || 'nl']}</Typography>
    </Box>
  </>);
}